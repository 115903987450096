import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { t } from '../../store/translations'
import ks_logo from './../../assets/img/ks_logo.png'
import Select from "react-select"
import Axios from "axios"
import { config } from '../../config'

export default class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.isIE = false || !!document.documentMode
  }
  render() {
    return (
      <div>
        <div className="p-3 bg-korianblue text-white sticky-bottom w-100">
          {this.isIE ? (
            ''
          ) : (
            <Container>
              <Row>
                <Col sm={12}>
                  <div className="text-center">
                    {t.contact[this.props.lang]}{process.env.REACT_APP_TEST}
                  </div>
                </Col>
                <div className="d-flex m-auto">
                  <img width="100px" src={ks_logo} alt="ks_logo" />
                </div>

                {/* <Col className="d-flex justify-content-center pt-3" sm={12}>
                    <div className="pr-2">
                      {t.change_country[this.props.lang]}:
                    </div>
                    <select
                      value={this.props.country_code}
                      onChange={(e) => this.props.setCountry_code(e.target.value)}
                    >
                      <option value="FR">FR</option>
                      <option value="IT">IT</option>
                      <option value="DE">DE</option>
                    </select>
                  </Col> */}
              </Row>
         
            </Container>
          )}
        </div>
      </div>
    )
  }
}

import React,{ useState, useEffect } from 'react';
import { EventType, InteractionType ,PublicClientApplication } from '@azure/msal-browser';
import { config } from '../config';
import { msalConfig,b2cPolicies } from '../config/auth.config'
import {
  MsalProvider,
} from '@azure/msal-react'

export default function withAuthProvider (WrappedComponent) {
  const msalInstance = new PublicClientApplication(msalConfig)


    return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        error: null,
        user: {},
        accessToken: null
      };
      // Initialize the MSAL application object
      this.publicClientApplication = new PublicClientApplication({
        auth: {
          clientId: config.appId,
          redirectUri: config.redirectUri
        },
        cache: {
          cacheLocation: "sessionStorage",
          storeAuthStateInCookie: true
        }
      });
    }

    async componentDidMount() {
      const callbackId = msalInstance.addEventCallback(async (event) => {
        if (event.eventType === EventType.LOGIN_FAILURE) {
          alert('Veuillez contacter votre administrateur pour réinitialiser votre mot de passe.')
          if (event.error && event.error.errorMessage.indexOf('AADB2C90118') > -1) {
            if (event.interactionType === InteractionType.Redirect) {
              await msalInstance.loginRedirect(b2cPolicies.authorities.forgotPassword)
            } else if (event.interactionType === InteractionType.Popup) {
              msalInstance.loginPopup(b2cPolicies.authorities.forgotPassword).catch(() => {
                return
              })
            }
          }
        }
  
        if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS || event.eventType === EventType.LOGIN_SUCCESS) {
          if (event?.payload) {
            if (event.payload.idToken && event.payload.idTokenClaims.oid) {
              //dispatch(authSliceActions.setUserEmail(event.payload.account.idTokenClaims.email))
              //dispatch(authSliceActions.setUserAccessToken(event.payload.idToken))
              
              this.setState({
                user: {
                  displayName: event.payload.account.idTokenClaims.name,
                  email: event.payload.account.idTokenClaims.email
                },
                accessToken: event.payload.idToken,
                error: null
              });
  
            } else {
              return instance.logout()
            }
            if (event.payload.idTokenClaims['acr'] === b2cPolicies.names.forgotPassword) {
              window.alert(
                'Password has been reset successfully. \nPlease sign-in with your new password',
              )
              return instance.logout()
            }
          }
        }
      })
  
      return () => {
        if (callbackId) {
          instance.removeEventCallback(callbackId)
        }
      }
    }

      
    render() {
      return (
      <MsalProvider instance={msalInstance}>
      <WrappedComponent
        error={this.state.error}
        user={this.state.user}
        login={() => this.login()}
        logout={() => this.logout()}
        accessToken={this.state.accessToken}
        setError={(message, debug) => this.setErrorMessage(message, debug)}
        msalInstance={msalInstance}
        {...this.props} />
            </MsalProvider>
        ) ;
    }
    


    login() {
      msalInstance.loginRedirect()
    }

    logout() {
      const logoutRequest = {
        account: msalInstance.getAccountByUsername(this.state.user.email)
      };

      msalInstance.logoutRedirect(logoutRequest);

    }

   
   
    setErrorMessage(message, debug) {
      this.setState({
        error: { message: message, debug: debug }
      });
    }

    normalizeError(error) {
      var normalizedError = {};
      if (typeof (error) === 'string') {
        var errParts = error.split('|');
        normalizedError = errParts.length > 1 ?
          { message: errParts[1], debug: errParts[0] } :
          { message: error };
      } else {
        normalizedError = {
          message: error.message,
          debug: JSON.stringify(error)
        };
      }
      return normalizedError;
    }

    isInteractionRequired(error) {
      if (!error.message || error.message.length <= 0) {
        return false;
      }

      return (
        error.message.indexOf('consent_required') > -1 ||
        error.message.indexOf('interaction_required') > -1 ||
        error.message.indexOf('login_required') > -1 ||
        error.message.indexOf('no_account_in_silent_request') > -1
      );
    }
  }
}

export const t = {
  SSO_button: {
    FR: 'Se connecter avec un email Korian',
    DE: 'Anmeldung mit Ihrer Korian-E-mail-Adresse',
    EN: 'Connect with your Korian email',
    IT: 'Connettiti attraverso la tua mail Korian',
  },
  quest_access_button: {
    FR: 'Accéder au questionnaire',
    DE: 'Zugang zur Umfrage',
    EN: 'Start the survey',
    IT: 'Accedi al questionario',
  },
  app_title: {
    FR: 'Outil de centralisation ANAP',
    DE: 'Unterstützungstool zur Nachverfolgung von Epidemien',
    EN: 'Epidemic monitoring tool',
    IT: 'Strumento per il monitoraggio epidemia',
  },
  home_button: {
    FR: 'Questionnaires',
    DE: 'Surveys',
    EN: 'Surveys',
    IT: 'Surveys',
  },
  history_button: {
    FR: 'Téléchargements',
    DE: 'Frühere Meldungen',
    EN: 'Downloads',
    IT: 'Downloads',
  },
  all: {
    FR: 'tous',
    DE: 'alle',
    EN: 'all',
    IT: 'tutti',
  },
  today: {
    FR: 'aujourd hui',
    DE: 'heute',
    EN: 'today',
    IT: 'al momento',
  },
  contact: {
    FR: 'Powered by Korian Solutions',
    DE: 'Powered by Korian Solutions',
    EN: 'Powered by Korian Solutions',
    IT: 'Powered by Korian Solutions',
  },
  contact_rights: {
    FR: "Contactez ksit@korian.com pour les droits d'accès",
    DE: 'Kontaktieren Sie ksit@korian.com für Zugangsrechte',
    EN: 'Contact ksit@korian.com for access rights',
    IT: 'Contatta Ksit@korian.com per i permessi di accesso',
  },
  access_questions: {
    FR: 'Accéder au questionnaire',
    DE: 'Zugang zu den Fragen',
    EN: 'access the questions',
    IT: 'accedi alle domande',
  },
  covid_cases: {
    FR: 'Les cas de covid',
    DE: 'Fälle von Covid',
    EN: 'COVID cases',
    IT: 'Casi di covid',
  },
  residents: {
    FR: 'Résidents',
    DE: 'Einwohner',
    EN: 'Residents',
    IT: 'Pazienti',
  },
  employees: {
    FR: 'Employés',
    DE: 'Mitarbeiter',
    EN: 'Employees',
    IT: 'Operatori',
  },
  confirmed: {
    FR: 'confirmé',
    DE: 'bestätigt',
    EN: 'confirmed',
    IT: 'confermato',
  },
  suspected: {
    FR: 'suspecté',
    DE: 'vermutet',
    EN: 'suspected',
    IT: 'sospetto',
  },
  total_deaths: {
    FR: 'Nombre total de décès',
    DE: 'Todesfälle insgesamt',
    EN: 'Total deaths',
    IT: 'totale deceduti',
  },
  total_recovered: {
    FR: 'Total guéri',
    DE: 'Vollständig geheilt',
    EN: 'Total recovered',
    IT: 'totale guariti',
  },
  total_cases: {
    FR: 'Nombre total de cas',
    DE: 'Gesamtzahl der Fälle',
    EN: 'Total cases',
    IT: 'numero totale dei casi',
  },
  comment: {
    FR: 'Commentaires',
    DE: 'Kommentare',
    EN: 'Comment',
    IT: 'commenti',
  },
  epidemic_level: {
    FR: 'niveau épidémique',
    DE: 'Epidemie-Level',
    EN: 'epidemic level',
    IT: 'livello epidemia',
  },
  level: {
    FR: 'Niveau',
    DE: 'Ebene',
    EN: 'Level',
    IT: 'Livello',
  },
  currently_sick: {
    FR: 'Actuellement malade',
    DE: 'Derzeit krank',
    EN: 'Currently sick',
    IT: 'Attualmente malato',
  },
  see_procedure: {
    FR: "voir procédure d'hygiène Korian",
    DE: 'siehe Korianisches Hygieneverfahren',
    EN: 'see Korian hygiene procedure',
    IT: 'cfr procedura igiene korian',
  },
  confirmed_sick: {
    FR:
      'Les cas de malades confirmés ont diminué sans que cela n\'entraîne une augmentation correspondante des décès ou des guérisons. Veuillez fournir une explication dans la section "Commentaires".',
    DE:
      'Die Zahl der bestätigten Krankheitsfälle ist zurückgegangen, ohne dass die Zahl der Todesfälle oder Genesungen entsprechend zugenommen hätte. Bitte geben Sie eine Erklärung im Kommentarabschnitt ab.',
    EN:
      'Confirmed sick have decreased without matching increase in deaths or recoveries. Please provide an explanation in the comment section.',
    IT:
      'il numero di Pazienti/Ospiti malati è diminuito senza corrispondente variazione nel valore dei deceduti o guariti. Per favore inserire una nota esplicativa nella sezione commenti.',
  },
  tests: {
    FR: 'Total tests réalisés',
    DE: 'Tests',
    EN: 'Tests',
    IT: 'Tests',
  },
  positive_tests: {
    FR: 'Tests positifs',
    DE: 'Positive Tests',
    EN: 'Positive tests',
    IT: 'Tests positivi',
  },
  negative_tests: {
    FR: 'Tests négatifs',
    DE: 'Negative Tests',
    EN: 'Negative tests',
    IT: 'Tests negativi',
  },
  remaining_tests: {
    FR: 'Tests restants',
    DE: 'Laufende Tests',
    EN: 'Remaining tests',
    IT: 'Tests in attesa di esito',
  },
  new: {
    FR: 'nouveau',
    DE: 'neu',
    EN: 'new',
    IT: 'nuovo',
  },
  new_deaths: {
    FR: 'Nouveaux décès',
    DE: 'Neue Todesfälle',
    EN: 'New deaths',
    IT: 'Nuovi decessi',
  },
  new_recoveries: {
    FR: 'Nouveaux guéris',
    DE: 'Neu geheilt',
    EN: 'New recoveries',
    IT: 'Appena guarito',
  },
  total: {
    FR: 'total',
    DE: 'gesamt',
    EN: 'total',
    IT: 'totale',
  },
  submit: {
    FR: 'Soumettre',
    DE: 'Einreichen',
    EN: 'Submit',
    IT: 'Compilazione',
  },
  submission: {
    FR: 'Déclaration enregistrée',
    DE: 'Einreichung gesendet',
    EN: 'Submission sent',
    IT: 'dati inviati',
  },
  download_previous_declarations: {
    FR: 'Déclarations entre deux dates',
    DE: 'Frühere Erklärungen herunterladen',
    EN: 'Download previous declarations',
    IT: 'Scarica i dati precedenti',
  },
  download_latest_declarations: {
    FR: "Dernières saisies jusqu'à une date",
    DE: 'Last submissions',
    EN: 'Last submissions',
    IT: 'Last submissions',
  },
  starting_on: {
    FR: 'à partir du',
    DE: 'von der',
    EN: 'starting on',
    IT: 'dal',
  },
  ending_on: {
    FR: 'Episode terminant le',
    DE: 'Ende am',
    EN: 'Ending on',
    IT: 'Fine su',
  },
  before: {
    FR: 'avant le',
    DE: 'vor',
    EN: 'before',
    IT: 'prima di',
  },
  to_date: {
    FR: "jusqu'au",
    DE: 'vor',
    EN: 'before',
    IT: 'prima di',
  },
  not_declaration: {
    FR: 'Aucune déclaration pour ces dates',
    DE: 'Zwischen diesen Terminen gibt es keine Erklärung!',
    EN: 'There is no declaration between these dates!',
    IT: 'Non ci sono dati inseriti in queste date',
  },
  obtain: {
    FR: 'obtenir les déclarations',
    DE: 'Aussagen einholen',
    EN: 'obtain statements',
    IT: 'ottenere dichiarazioni',
  },
  download_csv: {
    FR: 'télécharger CSV',
    DE: 'CSV herunterladen',
    EN: 'download CSV',
    IT: 'scarica CSV',
  },
  logout: {
    FR: 'Déconnexion',
    DE: 'Abmeldung',
    EN: 'Logout',
    IT: 'Logout',
  },
  login: {
    FR: 'Connexion',
    DE: 'Anmeldung',
    EN: 'Login',
    IT: 'Login',
  },
  dont_access: {
    FR: "Vous n'avez accès à aucun  établissement",
    DE: 'Sie haben keinen Zugang zu irgendeiner Einrichtung',
    EN: 'You do not have access to any establishment',
    IT: 'Non avete accesso ad alcuna struttura',
  },
  change_country: {
    FR: 'changer de pays',
    DE: 'Land wechseln',
    EN: 'change country',
    IT: 'cambia paese',
  },
  //CSV table translate
  est: {
    FR: 'Etablissement',
    DE: 'Einrichtung',
    EN: 'Establishment',
    IT: 'Stabilimento',
  },
  declarative: {
    FR: 'ID Déclaratif',
    DE: 'Deklarative ID',
    EN: 'Declarative ID',
    IT: 'ID dichiarativo',
  },
  created: {
    FR: 'Créé le (UTC)',
    DE: 'Erstellt am (UTC)',
    EN: 'Created the (UTC)',
    IT: 'Creato su (UTC)',
  },
  created_by: {
    FR: 'Créé par',
    DE: 'Erstellt von',
    EN: 'Created by',
    IT: 'Creato da',
  },
  resident_confirmed_new_cases: {
    FR: 'Nouveaux cas confirmés chez les résidents',
    DE: 'Neue bestätigte Fälle bei Anwohnern',
    EN: 'New confirmed cases among resident',
    IT: 'Nuovi casi confermati nei residenti',
  },
  resident_not_confirmed_new_sick: {
    FR: 'Résident non confirmé nouveau malade',
    DE: 'Einwohner nicht bestätigt neu krank',
    EN: 'Resident not confirmed new sick',
    IT: 'Residente non confermato nuovo malato',
  },
  resident_confirmed_new_recovered: {
    FR: 'Résident confirmé nouveau guéri',
    DE: 'Bewohner bestätigt neu geheilt',
    EN: 'Resident confirmed new recovered',
    IT: 'residente confermato nuovo guarito',
  },
  resident_not_confirmed_new_recovered: {
    FR: 'Résident non confirmé nouveau guéri',
    DE: 'Unbestätigter Bewohner neu geheilt',
    EN: 'Resident not confirmed new recovered',
    IT: 'Residente non confermato nuovo guarito',
  },
  employee_confirmed_new_sick: {
    FR: 'Employé confirmé nouveau malade',
    DE: 'Mitarbeiterin bestätigt neue Krankheit',
    EN: 'Employee confirmed new sick',
    IT: 'Dipendente confermato nuovo malato',
  },
  employee_not_confirmed_new_sick: {
    FR: 'Employé non confirmé nouveau malade',
    DE: 'Angestellter nicht als neuer Kranker bestätigt',
    EN: 'Employee not confirmed new sick',
    IT: 'Dipendente non confermato nuovo malato',
  },
  employee_confirmed_new_recovered: {
    FR: 'Employé confirmé nouveau guéri',
    DE: 'Bestätigter Mitarbeiter neu geheilt',
    EN: 'Employee confirmed new recovered',
    IT: 'Dipendente confermato nuovo guarito',
  },
  employee_not_confirmed_new_recovered: {
    FR: 'Employé non confirmé nouveau guéri',
    DE: 'Unbestätigter Mitarbeiter neu geheilt',
    EN: 'Employee not confirmed new recovered',
    IT: 'Dipendente non confermato nuovo curato',
  },
  resident_nb_positif_covid_tests: {
    FR: 'Résident nombre positif aux tests COVID',
    DE: 'Einwohnerzahl bei COVID-Tests positiv',
    EN: 'Resident number positive on COVID tests',
    IT: 'Numero di residenti positivi ai test COVID',
  },
  resident_nb_negatif_covid_tests: {
    FR: 'Résident nombre négatif aux tests COVID',
    DE: 'Einwohnerzahl bei COVID-Tests negativ',
    EN: 'Resident number negative on COVID tests',
    IT: 'Numero di residenti negativo ai test COVID',
  },
  resident_nb_done_covid_tests: {
    FR: 'Nombre de résidents ayant passé les tests COVID',
    DE: 'Nummer des Einwohners durchgeführte COVID-Tests',
    EN: 'Resident number done COVID tests',
    IT: 'Numero del residente fatto test COVID',
  },
  employee_nb_positif_covid_tests: {
    FR: 'Employé nombre positif aux tests COVID',
    DE: 'Mitarbeiterzahl bei COVID-Tests positiv',
    EN: 'Employee number positive on COVID tests',
    IT: 'Numero di dipendenti positivi ai test COVID',
  },
  employee_nb_negatif_covid_tests: {
    FR: "Nombre d'employés tests covid négatifs",
    DE: 'Mitarbeiterzahl negative Covid-Tests',
    EN: 'Employee number negative covid tests',
    IT: 'Numero di dipendenti test covidi negativi',
  },
  employee_nb_done_covid_tests: {
    FR: "Nombre d'employés ayant effectué les tests COVID",
    DE: 'Anzahl der Mitarbeiter, die COVID-Tests durchgeführt haben',
    EN: 'Employee number done COVID tests',
    IT: 'Numero di dipendenti fatto test COVID',
  },
  resident_confirmed_new_death: {
    FR: 'Résident confirmé nouveau décès',
    DE: 'Einwohner bestätigt neuen Tod',
    EN: 'Resident confirmed new death',
    IT: 'residente confermato nuovo decesso',
  },
  resident_not_confirmed_new_death: {
    FR: 'Résident non confirmé nouveau décès',
    DE: 'Einwohner nicht bestätigt neuer Tod',
    EN: 'Resident not confirmed new death',
    IT: 'Residente non confermato nuovo decesso',
  },
  employee_confirmed_new_death: {
    FR: 'Employé confirmé nouveau décès',
    DE: 'Mitarbeiter bestätigt neuen Tod',
    EN: 'Employee confirmed new death',
    IT: 'dipendente ha confermato il nuovo decesso',
  },
  employee_not_confirmed_new_death: {
    FR: 'Employé non confirmé nouveau décès',
    DE: 'Mitarbeiter nicht bestätigt neuer Tod',
    EN: 'Employee not confirmed new death',
    IT: 'Dipendente non confermato nuovo decesso',
  },
  resident_nb_covid_tests_to_be_done: {
    FR: 'Nombre de résidents tests COVID à effectuer',
    DE:
      'Durchführung von Tests zur Bestimmung der Zahl der Covidae bei den Einwohnern',
    EN: 'Resident number COVID tests to be done',
    IT: 'Numero residente covid test da effettuare',
  },
  employee_nb_covid_tests_to_be_done: {
    FR: "Nombre d'employé Tests COVID à effectuer",
    DE: 'Anzahl der durchzuführenden COVID-Tests der Mitarbeiter',
    EN: 'Employee number COVID tests to be done',
    IT: 'Numero di dipendenti COVID test da eseguire',
  },
  holiday_in_risk_area: {
    FR: 'Vacances dans une zone à risque au cours des 14 derniers jours ?',
    DE: 'Urlaub in einem Risikogebiet in den letzten 14 Tagen?',
    EN: 'Holiday in risk area in the last 14 days?',
    IT: 'Vacanze in area a rischio negli ultimi 14 giorni?',
  },
  participation_major_event: {
    FR:
      'Participation à un événement majeur au cours des 14 derniers jours (plus de 20 personnes) ?',
    DE:
      'Teilnahme an einer Großveranstaltung in den letzten 14 Tagen (mehr als 20 Personen)?',
    EN:
      'Participation in a major event in the last 14 days (more than 20 persons)?',
    IT:
      'Partecipazione ad un evento importante negli ultimi 14 giorni (più di 20 persone)?',
  },
  resident_receive_visitors: {
    FR:
      'Le résident a-t-il reçu des visiteurs au cours des 14 derniers jours ?',
    DE: 'Erhielt der Bewohner in den letzten 14 Tagen Besuch?',
    EN: 'Did the resident receive visitors in the last 14 days?',
    IT: 'Il residente ha ricevuto visite negli ultimi 14 giorni?',
  },
  date_visit: {
    FR: 'Date de la visite',
    DE: 'Datum des Besuchs',
    EN: 'Date of the visit',
    IT: 'Data della visita',
  },
  yes: {
    FR: 'Oui',
    DE: 'Ja',
    EN: 'Yes',
    IT: 'Sì',
  },
  no: {
    FR: 'Non',
    DE: 'Nein',
    EN: 'No',
    IT: 'No',
  },
  no_info: {
    FR: 'Aucune information',
    DE: 'Keine information',
    EN: 'No information',
    IT: 'Nessuna informazione',
  },
  msg_ie: {
    FR:
      "Cet outil ne fonctionne pas bien sur le navigateur Internet Explorer, merci d'utiliser un autre navigateur (Chrome, Edge, Firefox)",
    DE:
      'Dieses Tool funktioniert nicht gut mit Internet Explorer, bitte verwenden Sie einen anderen Browser (Chrome, Edge, Firefox).',
    EN:
      'This tool does not work well on Internet Explorer, please use another browser (Chrome, Edge, Firefox).',
    IT:
      'Questo strumento non funziona bene su Internet Explorer, si prega di utilizzare un altro browser (Chrome, Edge, Firefox).',
  },
  chrome: {
    FR: 'Télécharger Chrome',
    DE: 'Chrome herunterladen',
    EN: 'Download Chrome',
    IT: 'Scaricare Chrome',
  },
  firefox: {
    FR: 'Télécharger Firefox',
    DE: 'Firefox herunterladen',
    EN: 'Download Firefox',
    IT: 'Scaricare Firefox',
  },
  edge: {
    FR: 'Ouvrir avec Microsoft Edge',
    DE: 'Öffnen mit Microsoft Edge',
    EN: 'Open with Microsoft Edge',
    IT: 'Apri con Microsoft Edge',
  },
  resident_confirmed_current_sick: {
    FR: 'Résident confirmé malade actuel',
    DE: 'Einwohner bestätigt derzeit krank',
    EN: 'Resident confirmed current sick',
    IT: 'Residente confermato malato in corso confermato',
  },
  resident_not_confirmed_current_sick: {
    FR: 'Résident non confirmé malade actuel',
    DE: 'Einwohner nicht bestätigt derzeit krank',
    EN: 'Resident not confirmed current sick',
    IT: 'Residente non confermato malato in corso',
  },
  employee_confirmed_current_sick: {
    FR: 'Employé confirmé malade actuellement',
    DE: 'Mitarbeiter als derzeit krank bestätigt',
    EN: 'Employee confirmed current sick',
    IT: 'Dipendente confermato malato in corso',
  },
  employee_not_confirmed_current_sick: {
    FR: 'Employé non confirmé malade actuellement',
    DE: 'Mitarbeiter nicht bestätigt derzeit krank',
    EN: 'Employee not confirmed current sick',
    IT: 'Dipendente non confermato malato in corso',
  },
  establishment_reference: {
    FR: 'Accès',
    DE: 'Access rights',
    EN: 'Access rights',
    IT: 'Access rights',
  },
  status: {
    FR: 'Clôtures',
    DE: 'Status',
    EN: 'Status',
    IT: 'Status',
  },
  awaiting_validation: {
    FR: 'En attente de validation',
    DE: 'Warten auf Validierung',
    EN: 'Awaiting validation',
    IT: 'In attesa di convalida',
  },
  consult_the_establishment: {
    FR: 'Etablissements',
    DE: 'Einrichtungen',
    EN: 'Establishments',
    IT: 'Stabilimenti',
  },
  consult_the_contact: {
    FR: 'Contacts',
    DE: 'Ansprechpartner',
    EN: 'Contacts',
    IT: 'Contatti',
  },
  consult_the_regional: {
    FR: 'Régions',
    DE: 'Regionen',
    EN: 'Regions',
    IT: 'Regioni',
  },
  select: {
    FR: 'Sélectionner',
  },
  save: {
    FR: 'Sauvegarder',
  },
  cancel: {
    FR: 'Annuler',
  },
}

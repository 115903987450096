import React, { useEffect, useState } from 'react'
import CsvDownloader from 'react-csv-downloader'
import { Button } from 'reactstrap'
import DataAccessService from '../services/data-acces.service'
import EtbIdMapper from '../helpers/EtbIdMapper'

const columns = [
  {
    id: 'id_etablissement',
    displayName: 'Id Etablissement',
  },
  {
    id: 'nom_etablissement',
    displayName: 'Nom Etablissement',
  },
  { id: 'id', displayName: 'Id Indicateur' },
  {
    id: 'titre',
    displayName: 'Nom Indicateur',
  },
  {
    id: 'source',
    displayName: 'Source Indicateur',
  },
  {
    id: 'valeur',
    displayName: 'Valeur Indicateur',
  },
]

const Download = (props) => {
  const [data, setData] = useState(false)

  function getDownloadData() {
    DataAccessService.get('/downloadAll', { year: props.yearOfInterest }).then(
      (data) => {
        let newData = EtbIdMapper(data.data, props.facilities, 'id_etab')
        let dataDownload = newData.map((item) => {
          return {
            id_etablissement: item.id_etab,
            nom_etablissement: item.nom_etablissement,
            id: item.id_anap,
            titre: item.Titre,
            source: item.Source,
            valeur: item.valeur,
          }
        })
        setData(dataDownload)
        const csvButton = document.getElementById('csv-download')
        csvButton.click()
      },
    )
  }

  return (
    <div className="container py-4 d-flex align-items-center justify-content-center">
      <CsvDownloader
        className="download d-none"
        filename="Toute la base"
        separator=";"
        wrapColumnChar=""
        columns={columns}
        datas={data}
      >
        <Button id="csv-download" color="info" className="h-35">
          <i className="far fa-arrow-alt-circle-down px-2"></i>
          Télécharger
        </Button>
      </CsvDownloader>

      <Button color="info" className="h-35" onClick={getDownloadData}>
        <i className="far fa-arrow-alt-circle-down px-2"></i>
        Télécharger
      </Button>
    </div>
  )
}

export default Download

import React, { Component, useState, useEffect } from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import Select from 'react-select'
import DataAccessService from '../services/data-acces.service'
import Loader from '../components/Widgets/Loader'
import { Tabs, Tab } from 'react-bootstrap'
import { Button } from 'react-bootstrap'
import moment from 'moment'
import EtbIdMapper from '../helpers/EtbIdMapper'

async function openInvestigationRequest(id_etb) {
  await DataAccessService.post('/openInvestigation', { facility_id: id_etb })
}

function OpenInvestigation(props) {
  console.log(props)
  return (
    <Button
      className="d-flex justify-content-center"
      onClick={async () => {
        await openInvestigationRequest(props.dataRow.facility_id)
        props.refreshDataTable()
      }}
    >
      Décloturer
    </Button>
  )
}

function FormatDate(props) {
  return <div>{moment(props.valeur).format('DD/MM/YYYY')}</div>
}

export default class ClosedInvestigation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      missingIndicatorsTable: [],
      loading: true,
    }

    this.options = {
      page: 1,
      sizePerPage: 20,
      pageStartIndex: 1,
      paginationSize: 3,
      prePage: 'Prec',
      nextPage: 'Suiv',
      firstPage: '<<',
      lastPage: '>>',
    }
  }

  componentDidMount() {
    this.refreshDataTable()
  }

  refreshDataTable() {
    console.log('this', this)
    this.setState({ missingIndicatorsTable: [], loading: true })
    DataAccessService.get('/getClosedInvestigationEtb', {year:this.props.yearOfInterest}).then((data) => {
      let temp = EtbIdMapper(data.data, this.props.facilities, 'facility_id')
      this.setState({ missingIndicatorsTable: temp, loading: false })    })
  }
  render() {
    return (
      <div className="mx-auto row col-10 align-middle">
        <form className="form">
          <div className="form-group mt-4">
            <div className="py-3">
              {this.state.loading && <Loader position="relative" />}
              <div className="h3 mt-3 mb-4 text-center">
                LISTE ENQUÊTES CLÔTURÉES DE
              </div>
              <BootstrapTable
                data={this.state.missingIndicatorsTable}
                pagination={true}
                options={this.options}
                className="Table"
              >
                <TableHeaderColumn
                  dataField="facility_id"
                  width="4%"
                  isKey
                  dataSort
                >
                  Id
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="nom_etablissement"
                  filter={{ type: 'TextFilter', defaultValue: '' }}
                  dataSort
                >
                  Nom Etablissement
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="missing_indicators"
                  filter={{ type: 'TextFilter', defaultValue: '' }}
                  dataSort
                >
                  Indicateurs Manquants
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="email"
                  filter={{ type: 'TextFilter', defaultValue: '' }}
                  dataSort
                >
                  Par
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="closed_at"
                  filter={{ type: 'TextFilter', defaultValue: '' }}
                  dataSort
                  dataFormat={(text, element) => {
                    return <FormatDate dataRow={element} valeur={text} />
                  }}
                >
                  Date
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataFormat={(text, element) => {
                    return (
                      <OpenInvestigation
                        dataRow={element}
                        refreshDataTable={() => this.refreshDataTable()}
                      />
                    )
                  }}
                >
                  Décloturer enquête
                </TableHeaderColumn>
              </BootstrapTable>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

//local
// export const config = {
//   backendUrl: 'http://localhost:8000',
//   backendSso: 'http://localhost:8080',
//   appId: '3acd2086-1b80-4404-868c-e8c53c1c1823',
//   scopes: ['user.read'],
//   defaultUser: {
//     email: 'francois.dechalendar@korian.fr',
//     displayName: 'De chalendar François',
//     displayableId: 'francois.dechalendar@korian.fr',
//   },
// }

// staging
// export const config = {
//   backendUrl: 'https://back-staging1.azurewebsites.net',
//   backendSso: 'https://api-referentiel-dk.azurewebsites.net',
//   redirectUri: 'https://korian-staging1.azurewebsites.net',
//   appId: '78e89dd2-40ec-4502-8d84-8f9ac1e06bbf',
//   scopes: ['user.read'],
//   defaultUser: {
//     email: 'francois.dechalendar@korian.fr',
//     displayName: 'De chalendar François',
//     displayableId: 'francois.dechalendar@korian.fr',
//   },
// }

//prod
export const config = {
   backendUrl: 'https://back-anap.azurewebsites.net',
   backendSso: 'https://api-referentiel-dk.azurewebsites.net',
   redirectUri: 'https://korian-anap.azurewebsites.net/',
   appId: '78e89dd2-40ec-4502-8d84-8f9ac1e06bbf',
   apimref: 'https://api.korian-solutions.fr/ref',
   scopes: ['user.read'],
 }

//dev
/*
 export const config = {
  backendUrl: 'http://localhost:8000',
  backendSso: 'https://api-referentiel-dk.azurewebsites.net',
  redirectUri: 'https://korian-anap.azurewebsites.net/',
  apimref: 'https://api.korian-solutions.fr/ref',
  appId: '78e89dd2-40ec-4502-8d84-8f9ac1e06bbf',
  scopes: ['user.read'],
}*/

import React from 'react';
import {t} from '../../store/translations'

export default function Browser(props) {

  return (
    <div className="text-center">
        <div className="py-3 py-lg-4"></div>
        <div className="">
            {t.msg_ie[props.lang]}
        </div>
        <div className="py-3 py-lg-5">
            <a className="btn btn-info" href={props.link}>{t.edge[props.lang]}</a>
        </div>
        {/* <div className="py-lg-5">
            <a className="btn btn-info" href="https://www.google.fr/chrome/?brand=CHBD&gclid=Cj0KCQjw28T8BRDbARIsAEOMBczPOZmn1vkVcDxqm2_pW6tE4AnKVaUzxTdxslhd3NgEdupGdEjFnDIaAnBqEALw_wcB&gclsrc=aw.ds">{t.chrome[props.lang]}</a>
        </div>
        <div className="">
            <a className="btn btn-info" href="https://www.mozilla.org/fr/firefox/new/">{t.firefox[props.lang]}</a>
        </div> */}
    </div>
    );
}

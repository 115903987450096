import React, { useState } from 'react'
import Select from 'react-select'
import { Container, Row, Col } from 'reactstrap'
import Axios from 'axios'
import { config } from '../config'
import Loader from '../components/Widgets/Loader'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

export default function YearSelecter(props) {
  const [showModal, setShowModal] = useState(false)

  const handleClose = () => setShowModal(false)
  const handleShow = () => setShowModal(true)
  const years = [
    { value: 2022, label: '2022' },
    { value: 2023, label: '2023' },
    { value: 2024, label: '2024' },
    { value: 2025, label: '2025' },
  ]

  function changeSelectedYear(e, setYearOfInterest) {
    setYearOfInterest(e.value)
  }

  function postSelectedYear() {
    props.setLoad(true)
    const timer = setTimeout(() => {
      Axios.post(config.backendUrl + '/postYear', {
        year: props.yearOfInterest,
      })
      props.setLoad(false)
    }, 1000)
  }

  function InfoModal() {
    return (
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Changement d'année</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Vous êtes sur le point de changer l’année de reporting de l’enquête
          ANAP. Si vous modifiez l’année, veuillez aussi déclôturer les enquêtes
          afin d’autoriser les établissements à saisir pour cette nouvelle
          année.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Fermer
          </Button>
          <Button variant="info" onClick={postSelectedYear}>
            Sauvegarder année
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  function deleteCloture() {
    props.setLoad(true)
    const timer = setTimeout(() => {
      Axios.delete(config.backendUrl + '/deleteClosedInvestigation')
      props.setLoad(false)
    }, 1000)
  }

  return (
    <Container>
      {props.load && <Loader />}
      {props.authorizedUsers.includes(props.user.email) && (
        <>
          <Row>
            <Col className="text-center h3 pt-4">Changement d'année</Col>
          </Row>
          <div className='border-top'/>
          <Row className="text-center m-auto py-4 justify-content-center col-10">
            <Col sm={4} className="pl-4">
              <Select
                options={years}
                value={{
                  label: props.yearOfInterest,
                  value: props.yearOfInterest,
                }}
                onChange={(e) => changeSelectedYear(e, props.setYearOfInterest)}
              />
            </Col>
            <Col sm={4} className="ml-3 text-left">
              <Button className="btn-info" onClick={(e) => setShowModal(true)}>
                Valider
              </Button>
            </Col>
          </Row>
          <div className='border-top'/>
          <Row className="text-center m-auto py-4 justify-content-center col-10">
            <Button className="btn-danger" onClick={(e) => deleteCloture()}>
              Décloturer enquêtes
            </Button>
          </Row>
        </>
      )}
      <InfoModal />
      <div className="py-4"></div>
      <div className="py-4"></div>
      <div className="py-4"></div>
      <div className="py-4"></div>
      <div className="py-4"></div>
      <div className="py-4"></div>
      <div className="py-4"></div>
    </Container>
  )
}

export class FakedataService {
  static getFakeDataTable() {
    return [
      {
        id: 1,
        indicateur: 'Indicateur 1',
        libelle: 'Ergonomic directional toolset',
        source: 'Abernathy, Prosacco and Macejkovic',
        valeur: 452,
        'valeur N-1': 450,
        prenom: 'Marie-françoise',
        nom: 'Feedham',
      },
      {
        id: 2,
        indicateur: 'Indicateur 2',
        libelle: 'Seamless optimizing utilisation',
        source: 'Tillman, Franecki and Champlin',
        valeur: 452,
        'valeur N-1': 450,
        prenom: 'Dafnée',
        nom: 'Edmead',
      },
      {
        id: 3,
        indicateur: 'Indicateur 3',
        libelle: 'Customizable encompassing implementation',
        source: 'Funk Inc',
        valeur: 452,
        'valeur N-1': 450,
        prenom: 'Hélène',
        nom: 'Kunath',
      },
      {
        id: 4,
        indicateur: 'Indicateur 4',
        libelle: 'Intuitive bandwidth-monitored model',
        source: 'Gibson-Bayer',
        valeur: 452,
        'valeur N-1': 450,
        prenom: 'Lucrèce',
        nom: 'Antoszczyk',
      },
      {
        id: 5,
        indicateur: 'Indicateur 5',
        libelle: 'Reverse-engineered foreground monitoring',
        source: 'Kohler and Sons',
        valeur: 452,
        'valeur N-1': 450,
        prenom: 'Lén',
        nom: 'Burgoine',
      },
      {
        id: 6,
        indicateur: 'Indicateur 6',
        libelle: 'Centralized holistic open architecture',
        source: 'Kshlerin-Barton',
        valeur: 452,
        'valeur N-1': 450,
        prenom: 'Östen',
        nom: 'Lochrie',
      },
      {
        id: 7,
        indicateur: 'Indicateur 7',
        libelle: 'Cross-platform zero tolerance firmware',
        source: 'Klein Inc',
        valeur: 452,
        'valeur N-1': 450,
        prenom: 'Laurène',
        nom: 'Walesa',
      },
      {
        id: 8,
        indicateur: 'Indicateur 8',
        libelle: 'Networked global approach',
        source: 'Gusikowski Group',
        valeur: 452,
        'valeur N-1': 450,
        prenom: 'Vénus',
        nom: 'Haselwood',
      },
      {
        id: 9,
        indicateur: 'Indicateur 9',
        libelle: 'Stand-alone systemic leverage',
        source: 'Schamberger, Swaniawski and Lindgren',
        valeur: 452,
        'valeur N-1': 450,
        prenom: 'Maëlann',
        nom: 'Mungane',
      },
      {
        id: 10,
        indicateur: 'Indicateur 10',
        libelle: 'Managed exuding synergy',
        source: 'Friesen Inc',
        valeur: 452,
        'valeur N-1': 450,
        prenom: 'Måns',
        nom: 'Hatherley',
      },
    ]
  }

  static getOptionsEtb() {
    return [
      { value: '1', label: 'Korian les catalaunes' },
      { value: '2', label: 'Korian les Albizzias' },
      { value: '3', label: "Korian Jardin d'Hugo" },
    ]
  }

  static getOptionsSrc() {
    return [
      { value: '1', label: 'Contractualisation' },
      { value: '2', label: 'Juridique' },
      { value: '3', label: 'Tarification' },
    ]
  }
}

import React, { Component, useState, useEffect } from 'react'
import { Button } from 'reactstrap'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import Select from 'react-select'
import CsvDownloader from 'react-csv-downloader'
import ModalComment from '../components/Widgets/ModalComment'
import DataAccessService from '../services/data-acces.service'
import Loader from '../components/Widgets/Loader'
import { Tabs, Tab } from 'react-bootstrap'
import EtbIdMapper from '../helpers/EtbIdMapper'
import ProgressBar from 'react-bootstrap/ProgressBar'

function CompletionStatus(props) {
  return (
    <div>
      {props.dataRow.pct_completion > 80 ? (
        <ProgressBar
          variant="success"
          now={props.dataRow.pct_completion}
          label={props.dataRow.pct_completion}
        />
      ) : (
        <ProgressBar
          variant="danger"
          now={props.dataRow.pct_completion}
          label={props.dataRow.pct_completion}
        />
      )}
      {/* <ProgressBar variant="info" now={20} /> */}
      {/* <ProgressBar variant="warning" now={60} /> */}
    </div>
  )
}

export default class Completion extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dataTableEtabCompletion: [],
      dataTableSourceCompletion: [],
      dataTableMissingIndicator: [],
      indicatorList: [],
      selectedIndicator: {},
      loading: true,
    }

    this.options = {
      sortName:'id_etab',
      sortOrder:'asc',
      page: 1,
      sizePerPage: 20,
      pageStartIndex: 1,
      paginationSize: 3,
      prePage: 'Prec',
      nextPage: 'Suiv',
      firstPage: '<<',
      lastPage: '>>',
    }

    this.optionsAlt = {
      page: 1,
      sizePerPage: 20,
      pageStartIndex: 1,
      paginationSize: 3,
      prePage: 'Prec',
      nextPage: 'Suiv',
      firstPage: '<<',
      lastPage: '>>',
    }
  }
  handleSelectIndicator = (select) => {
    this.setState({ loading: true, dataTableMissingIndicator: [] })
    // const anap_id = select.label.split(' -')

    // console.log('select = ' + anap_id[0])
    DataAccessService.get('/getMissingSrcCompletion', {select:select.value, yearOfInterest:this.props.yearOfInterest}).then((data) => {
      let temp = EtbIdMapper(data.data, this.props.facilities, 'id_etab')
      this.setState({ dataTableMissingIndicator: temp, loading: false })
    }, console.log('select', select))
  }

  componentDidMount() {
    DataAccessService.get('/getEtbCompletion', {yearOfInterest:this.props.yearOfInterest}).then((data) => {
      console.log(data.data)
      let temp = EtbIdMapper(data.data, this.props.facilities, 'id_etab')
      this.setState({ dataTableEtabCompletion: temp, loading: false })
    })
    DataAccessService.get('/getSrcCompletion', {yearOfInterest:this.props.yearOfInterest}).then((data) => {
      this.setState({ dataTableSourceCompletion: data.data })
    })
    // DataAccessService.get('/getMissingSrcCompletion').then((data) => {
    // 	console.log('data', data)
    // 	this.setState({ dataTableMissingIndicator: data.data, loading: false })
    //   })
    DataAccessService.get('/getAllIndicators').then((data) => {
      let listIndicators = data.data.map((item) => {
        return {
          value: item.nouveau_code,
          label: item.nouveau_code + ' - ' + item.Titre,
        }
      })
      this.setState({ indicatorList: listIndicators })
    })
  }

  render() {
    return (
      <div className="mx-auto row col-10 align-middle">
        <form className="form">
          <Tabs id="uncontrolled-tab-example">
            <Tab eventKey="nbr-etb" title="NOMBRE INDICATEURS PAR ETB">
              <div className="form-group mt-4">
                <div className="py-3">
                  {this.state.loading && <Loader position="relative" />}
                  <div className="h3 mt-3 mb-4 text-center">
                    NOMBRE INDICATEURS PAR ETB
                  </div>
                  <BootstrapTable
                    data={this.state.dataTableEtabCompletion}
                    pagination={true}
                    options={this.options}
                    className="Table"
                  >
                    <TableHeaderColumn
                      dataField="id_etab"
                      isKey
                      dataSort
                      width="6%"
                    >
                      Id Etb
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="nom_etablissement"
                      filter={{ type: 'TextFilter', defaultValue: '' }}
                      dataSort
                    >
                      Nom Etablissement
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="nb_indicateurs_completes"
                      filter={{ type: 'TextFilter', defaultValue: '' }}
                      dataSort
                    >
                      Nb Indicateur Renseigné
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="nb_indicateurs_auto"
                      filter={{ type: 'TextFilter', defaultValue: '' }}
                      dataSort
                    >
                      Nb Indicateur Calculé
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="nb_indicateurs_total_a_renseigner"
                      filter={{ type: 'TextFilter', defaultValue: '' }}
                      dataSort
                    >
                      Indicateur a Renseigner
                    </TableHeaderColumn>
                    {/* <TableHeaderColumn
                      dataField="pct_completion"
                      filter={{ type: 'TextFilter', defaultValue: '' }}
                      dataSort
                    >
                      % Completion
                    </TableHeaderColumn> */}
                    <TableHeaderColumn
                      dataSort
                      dataField="pct_completion"
                      filter={{ type: 'TextFilter', defaultValue: '' }}
                      dataFormat={(text, element) => {
                        return <CompletionStatus dataRow={element} />
                      }}
                    >
                      % Completion
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="debut_saisie"
                      filter={{ type: 'TextFilter', defaultValue: '' }}
                      dataSort
                      width='9%'
                    >
                     Débuté saisie
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="cloture"
                      filter={{ type: 'TextFilter', defaultValue: '' }}
                      dataSort
                      width='8%'
                    >
                      Clôturé
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="missing_indicators"
                      dataSort
                      width='10%'
                    >
                      Indic manquants
                    </TableHeaderColumn>
                  </BootstrapTable>
                </div>
              </div>
            </Tab>
            <Tab eventKey="nbr-indic" title="NOMBRE ETB PAR INDICATEUR">
              <div className="form-group mt-4">
                <div className="py-3">
                  <div className="h3 mt-3 mb-4 text-center">
                    NOMBRE ETB PAR INDICATEUR
                  </div>
                  <BootstrapTable
                    data={this.state.dataTableSourceCompletion}
                    pagination={true}
                    options={this.optionsAlt}
                    className="Table"
                  >
                    <TableHeaderColumn
                      dataField="source"
                      isKey
                      filter={{ type: 'TextFilter', defaultValue: '' }}
                      dataSort
                    >
                      Source
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="titre"
                      filter={{ type: 'TextFilter', defaultValue: '' }}
                      dataSort
                    >
                      Indicateur
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="nb_etab"
                      filter={{ type: 'TextFilter', defaultValue: '' }}
                      dataSort
                    >
                      Nb Etablissement Avec Valeur
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="pct_completion"
                      filter={{ type: 'TextFilter', defaultValue: '' }}
                      dataSort
                    >
                      % Completion
                    </TableHeaderColumn>
                  </BootstrapTable>
                </div>
              </div>
            </Tab>
            <Tab
              eventKey="missing-ind"
              title="LISTE ETB AVEC INDICATEUR MANQUANT"
            >
              <div className="form-group mt-4">
                <div className="py-3">
                  <div className="h3 mt-3 mb-2 text-center">
                    LISTE ETB AVEC INDICATEUR MANQUANT
                  </div>
                  <div className="py-3 row justify-content-center">
                    <div className="col-2 mt-auto mb-auto">
                      <b>Sélectionner un indicateur :</b>
                    </div>
                    <div className="col-4">
                      <Select
                        placeholder="Rechercher un indicateur"
                        options={this.state.indicatorList}
                        onChange={(select) =>
                          this.handleSelectIndicator(select)
                        }
                      />
                    </div>
                  </div>
                  <BootstrapTable
                    data={this.state.dataTableMissingIndicator}
                    className="Table"
                    pagination={true}
                    options={this.optionsAlt}
                  >
                    <TableHeaderColumn
                      dataField="etabid"
                      isKey
                      dataSort
                    >
                      Id
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="Nom_etablissement"
                      filter={{ type: 'TextFilter', defaultValue: '' }}
                      dataSort
                    >
                      Nom établissement
                    </TableHeaderColumn>
                  </BootstrapTable>
                </div>
              </div>
            </Tab>
          </Tabs>
        </form>
      </div>
    )
  }
}

import { LogLevel } from '@azure/msal-browser'
const userFlow = 'B2C_1A_SIGNUP_SIGNIN'
const authority = 'https://b2ckssharedprod.b2clogin.com/b2ckssharedprod.onmicrosoft.com'
const authorityDomain = 'b2ckssharedprod.b2clogin.com'
const clientId = 'eef82fdc-39c7-4702-916a-9f25b676cb55'
const redirectUri = "https://anap.korian-solutions.fr"


export const b2cPolicies = {
  names: {
    signUpSignIn: userFlow,
    forgotPassword: userFlow,
    editProfile: userFlow,
  },
  authorities: {
    signUpSignIn: {
      authority: `${authority}/${userFlow}`,
    },
    editProfile: {
      authority: `${authority}/${userFlow}`,
    },
    forgotPassword: {
        authority: `${authority}/${userFlow}`,
        scopes: [],
    }
  },
  authorityDomain: authorityDomain,
}

export const msalConfig = {
  auth: {
    clientId: clientId,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: redirectUri,
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            return
          case LogLevel.Info:
            console.info(message)
            return
          case LogLevel.Verbose:
            console.debug(message)
            return
          case LogLevel.Warning:
            console.warn(message)
            return
        }
      },
    },
  },
}

export const loginRequest = {
  scopes: ['user.read', 'mail.send'],
}

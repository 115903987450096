import React, { Component } from 'react'
import { UploaderComponent } from '@syncfusion/ej2-react-inputs'
import { L10n } from '@syncfusion/ej2-base'
import { Button } from 'reactstrap'
import Downloader from '../helpers/downloader'
import { config } from '../config'

const allowedExtensions = '.xlsx, .xls, .csv'

const backendUrl = config.backendUrl.endsWith('/')
  ? config.backendUrl.slice(0, -1)
  : config.backendUrl

export default class Import extends Component {
  constructor() {
    super()
    this.state = {
      path: {
        saveUrl: `${backendUrl}/upload`,
      },
    }
  }

  componentWillMount() {
    L10n.load({
      'fr-FR': {
        uploader: {
          Browse: 'Parcourir',
          Clear: 'Effacer',
          Upload: 'Télécharger',
          cancel: 'Annuler',
          delete: 'Supprimer le fichier',
          dropFilesHint: 'Ou déposer des fichiers ici',
          inProgress: 'Téléchargement',
          invalidFileType: "Le type de fichier n'est pas autorisé",
          invalidMaxFileSize: 'La taille du fichier dépasse 28 Mo',
          invalidMinFileSize:
            "La taille du fichier est trop petite! S'il vous plaît télécharger des fichiers avec une taille minimale de 10 Ko",
          readyToUploadMessage: 'Prêt à télécharger',
          remove: 'Retirer',
          removedFailedMessage: "Le fichier n'a pas pu être supprimé",
          removedSuccessMessage: 'Fichier supprimé avec succès',
          uploadFailedMessage: "Impossible d'importer le fichier",
          uploadSuccessMessage: 'Fichier téléchargé avec succès',
        },
      },
    })
  }

  getFileTemplate() {
    Downloader.downloadFile([], `Fichier Exemple`, [
      'id_indicateur',
      'id_etablissement',
      'valeur',
    ])
  }

  render() {
    return (
      <div className="col-10 mx-auto">
        <h4 className="pt-4">Déposer les fichiers que vous voulez importer</h4>

        <div className="row py-4">
          <div className="col-6">
            <UploaderComponent
              id="uploads"
              allowedExtensions={allowedExtensions}
              autoUpload={false}
              asyncSettings={this.state.path}
              maxFileSize={5 * 10 ** 6}
              locale="fr-FR"
            />
          </div>
          <div className="col-6 d-flex text-center flex-column">
            <h5> Cliquez ici pour télécharger un exemplaire</h5>
            <Button
              className="col-4 mx-auto"
              color="info"
              onClick={this.getFileTemplate}
            >
              Télécharger
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default function (array1, facilities, idName) {
  console.log(array1, facilities, idName)
  array1.forEach((el) => {
    facilities.forEach((facility) => {
      if (parseInt(el[idName]) === facility.id) {
        el.distinction = facility.distinction
        el.nom_etablissement = facility.name
      }
    })
  })
  for (let i = array1.length - 1; i > 0; i--) {
    if (!array1[i].nom_etablissement) array1.splice(i, 1)
  }
  console.log(array1)
  return array1
}

import React, { Component, useState, useEffect } from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import Select from 'react-select'
import DataAccessService from '../services/data-acces.service'
import Loader from '../components/Widgets/Loader'
import { Tabs, Tab } from 'react-bootstrap'
import { Button } from 'react-bootstrap'
import moment from 'moment'

function parseHeaders(str) {
  str = str.toLowerCase()
  str = str.charAt(0).toUpperCase() + str.slice(1)
  str = str.replace(/_/g, ' ')
  return str
}

export default class Atypia extends Component {
  constructor(props) {
    super(props)
    this.state = {
      atypiaTable: [],
      loading: true,
      arrayTrigger: false,
      selectTable: [{ label: 'v19 > v20', value: 'v19_v20' }],
    }

    this.options = {
      page: 1,
      sizePerPage: 20,
      pageStartIndex: 1,
      paginationSize: 3,
      prePage: 'Prec',
      nextPage: 'Suiv',
      firstPage: '<<',
      lastPage: '>>',
    }
  }

  componentDidMount() {
    this.refreshDataTable()
  }

  refreshDataTable() {
    console.log('this', this)
    this.setState({ atypiaTable: [], loading: true })
    // DataAccessService.get('/getAtypia').then((data) => {
    //   this.setState({ atypiaTable: data.data, loading: false })
    // })
    this.setState({ loading: false })
  }

  handleSelectIndicator = (select) => {
    this.setState({ loading: true, atypiaTable: [] })
    // const anap_id = select.label.split(' -')

    // console.log('select = ' + anap_id[0])
    DataAccessService.get('/' + select.value).then((data) => {
      console.log(data.data)
      if (data.data.length > 0) {
        this.setState({
          arrayTrigger: true,
          atypiaTable: data.data,
          loading: false,
        })
      } else this.setState({ arrayTrigger: false, loading: false })
      // this.setState({
      //   atypiaTable: data.data,
      //   loading: false,
      // })
    }, console.log('select', select))
  }

  pushFirstRow() {
    return Object.keys(this.state.atypiaTable[0])
      .slice(0, 1)
      .map((element, index) => {
        return (
          <TableHeaderColumn dataField={element} key={index} dataSort isKey>
            {parseHeaders(element)}
          </TableHeaderColumn>
        )
      })
  }

  pushTableColumns() {
    return Object.keys(this.state.atypiaTable[0])
      .slice(1)
      .map((element, index) => {
        return (
          <TableHeaderColumn
            dataField={element}
            key={index}
            dataSort
            filter={{ type: 'TextFilter', defaultValue: '' }}
          >
            {parseHeaders(element)}
          </TableHeaderColumn>
        )
      })
  }

  render() {
    return (
      <div className="mx-auto col-10 align-middle">
        <form className="form">
          <div className="form-group mt-4">
            <div className="py-3 ">
              {this.state.loading && <Loader position="relative" />}
              <div className="h3 mt-3 mb-4 text-center">LISTE ATYPIES</div>
              <div className="py-3 row justify-content-center">
                <div className="col-2 mt-auto mb-auto">
                  <b>Sélectionner une atypie :</b>
                </div>
                <div className="col-4">
                  <Select
                    placeholder="Rechercher un indicateur"
                    options={this.state.selectTable}
                    onChange={(select) => this.handleSelectIndicator(select)}
                  />
                </div>
              </div>
              {this.state.arrayTrigger ? (
                <BootstrapTable
                  data={this.state.atypiaTable}
                  pagination={true}
                  options={this.options}
                  className="Table"
                >
                  {this.pushFirstRow()}
                  {this.pushTableColumns()}
                </BootstrapTable>
              ) : (
                <div className='border py-4 mt-3'><h4 className='text-center'>Aucune données</h4></div>
              )}
            </div>
          </div>
        </form>
      </div>
    )
  }
}

import React, { Component } from 'react'
import Select from 'react-select'
import DataAccessService from '../services/data-acces.service'
import { t } from '../store/translations'
import { Button } from 'reactstrap'
import Alert from 'react-bootstrap/Alert'
import Loader from '../components/Widgets/Loader'

const styleDivMain = { height: '68vh' }
export default class Welcome extends Component {
  constructor(props) {
    super(props)
    //console.log(this.props)
    this.state = {
      optionsListEtb: [],
      optionsListSrc: [],
      selectedSrc: null,
      selectedEtb: null,
    }
  }

  componentDidMount() {
    //console.log("facilities", this.props.facilities)
    //console.log('data', data)
    let listEtb = this.props.facilities.map((item) => {
      return { value: item.id, label: item.name }
    })
    this.setState({
      optionsListEtb: listEtb,
      selectedEtb: listEtb[0],
    })

    // DataAccessService.get('/getSrc').then(data => {
    //     console.log('data', data);
    //     let listSrc = data.data.map(item => {
    //         return { value: item.Source, label: item.Source}
    //     });
    //     this.setState({
    //         optionsListSrc: listSrc
    //     });
    // });
  }

  handleSelectValue(selectRef, selectData) {
    if (selectRef === 'selectEtb') {
      this.setState({ selectedEtb: selectData })
    } else {
      this.setState({ selectedSrc: selectData })
    }
  }

  redirectToListEtb(type) {
    console.log('this.state.selectedEtb ', this.state.selectedEtb)
    this.props.history.push({
      pathname: '/list-etb',
      state: {
        part: type,
        data: type === 'etb' ? this.state.selectedEtb : this.state.selectedSrc,
      },
    })
  }

  render() {
    return (
      <div>
        {this.props.load && <Loader />}
        <div
          className="py-1 pb-5 d-flex flex-column justify-content-center mx-auto"
          style={styleDivMain}
        >
          <div className="w-100 d-flex flex-column justify-content-center align-items-center">
            {!this.props.isAdmin && (
              <Alert variant="info">
                Si vous souhaitez obtenir un accès à cette plateforme pour un de
                vos collaborateurs, merci d'envoyer un mail à{' '}
                <Alert.Link
                  className="text-dark"
                  href="mailto:contact-kdto@korian.com"
                >
                  contact-kdto@korian.com
                </Alert.Link>{' '}
                avec son nom, prénom et adresse email korian.
              </Alert>
            )}
            <h3 className="w-50 text-center">
              Séléctionner un établissement pour afficher les données
            </h3>
            <div className="py-4"></div>
            <div className="col-6 py-3 d-flex justify-content-around align-items-start">
              <Select
                className="w-50"
                placeholder="Rechercher un étalissement"
                onChange={(data) => this.handleSelectValue('selectEtb', data)}
                value={this.state.selectedEtb}
                options={this.state.optionsListEtb}
              />
              <Button
                color="info"
                className="h-35"
                onClick={
                  this.state.selectedEtb
                    ? () => this.redirectToListEtb('etb')
                    : null
                }
              >
                {t.select[this.props.lang]}
                <i className="far fa-arrow-alt-circle-right px-2"></i>
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

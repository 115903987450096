import React, { useState, useEffect } from 'react'
import Login from '../components/Login/login'
import Browser from '../components/Browser/Browser'
import Welcome from './Welcome'
import ListEtb from './ListEtb'
import Import from './Import'
import { Route, Switch } from 'react-router-dom'
import Download from './Download'
import Completion from './Completion'
import ClosedInvestigation from './ClosedInvestigations'
import { config } from '../config'
import Atypies from './Atypies'
import YearSelecter from './YearSelecter'

const axios = require('axios')

export default function Main(props) {
  // Internet Explorer 6-11
  const isIE = /*@cc_on!@*/ false || !!document.documentMode
  const curentLink = window.location.href
  const link = 'microsoft-edge:' + curentLink

  return (
    <div>
      {isIE ? (
        <Browser lang={'FR'} link={link} />
      ) : props.loggedIn ? (
        <div>
          {/* <Router> */}
          <Switch>
            <Route path="/" exact>
              {(propRoute) => {
                return (
                  <div>
                    {/* <SubNav headers={ getHeader(1) }/> */}
                    <Welcome
                      {...propRoute}
                      lang={props.lang}
                      facilities={props.facilities}
                      isAdmin={props.isAdmin}
                    />
                  </div>
                )
              }}
            </Route>

            <Route path="/list-etb" exact>
              {(propRoute) => {
                return (
                  <div>
                    {/* <SubNav headers={ getHeader(1) }/> */}
                    <ListEtb
                      {...propRoute}
                      lang={props.lang}
                      isAdmin={props.isAdmin}
                      facilities={props.facilities}
                      setLoad={props.setLoad}
                      load={props.load}
                      user={props.user}
                      yearOfInterest={props.yearOfInterest}
                    />
                  </div>
                )
              }}
            </Route>

            <Route path="/import" exact>
              {(propRoute) => {
                return (
                  <div>
                    <Import {...propRoute} lang={props.lang} />
                  </div>
                )
              }}
            </Route>
            <Route path="/download" exact>
              {(propRoute) => {
                return (
                  <div>
                    <Download
                      {...propRoute}
                      lang={props.lang}
                      yearOfInterest={props.yearOfInterest}
                      facilities={props.facilities}
                    />
                  </div>
                )
              }}
            </Route>
            <Route path="/completion" exact>
              {(propRoute) => {
                return (
                  <div>
                    <Completion {...propRoute} lang={props.lang} yearOfInterest={props.yearOfInterest} facilities={props.facilities} />
                  </div>
                )
              }}
            </Route>
            <Route path="/closed-investigation" exact>
              {(propRoute) => {
                return (
                  <div>
                    <ClosedInvestigation
                      {...propRoute}
                      lang={props.lang}
                      yearOfInterest={props.yearOfInterest}
                      facilities={props.facilities}
                    />
                  </div>
                )
              }}
            </Route>
            <Route path="/atypies" exact>
              {(propRoute) => {
                return (
                  <div>
                    <Atypies {...propRoute} lang={props.lang} />
                  </div>
                )
              }}
            </Route>
            {props.authorizedUsers.includes(props.user.email) && (
              <Route path="/year-selector" exact>
                {(propRoute) => {
                  return (
                    <div>
                      <YearSelecter
                        {...propRoute}
                        yearOfInterest={props.yearOfInterest}
                        setYearOfInterest={props.setYearOfInterest}
                        user={props.user}
                        setLoad={props.setLoad}
                        load={props.load}
                        authorizedUsers={props.authorizedUsers}
                      />
                    </div>
                  )
                }}
              </Route>
            )}
          </Switch>
          {/* </Router> */}
        </div>
      ) : (
        <>
          <div>
            <Login
              lang={props.lang}
              login={props.login}
              isAuthorized={props.isAuthorized}
              user={props.user}
              facilityEmpty={props.facilityEmpty}
              instance={props.instance}
            />
          </div>
        </>
      )}
    </div>
  )
}

import React, { Component, useState, forceUpdate } from 'react'
import { Button } from 'reactstrap'
import TableList from '../components/TableList/TableList'
import { FakedataService } from '../services/fakedata.service'
import Select from 'react-select'
import CsvDownloader from 'react-csv-downloader'
import ModalComment from '../components/Widgets/ModalComment'
import DataAccessService from '../services/data-acces.service'
import Loader from '../components/Widgets/Loader'
import { config } from '../config'
import Alert from 'react-bootstrap/Alert'
import infoimgw from '../assets/img/icon_blue.png'
import Modal from 'react-bootstrap/Modal'
import { CSVLink } from 'react-csv'
import { saveAs } from 'file-saver'
import changeicon from '../assets/img/changeicon.png'
import EtbIdMapper from '../helpers/EtbIdMapper.js'

const columnsEtb = [
  {
    id: 'id',
    displayName: 'Id',
  },
  {
    id: 'nom_etablissement',
    displayName: 'Nom Etablissement',
  },
  {
    id: 'source',
    displayName: 'Source',
  },
  {
    id: 'titre',
    displayName: 'Titre',
  },
  {
    id: 'valeur',
    displayName: 'Valeur',
  },
]

const columnFiltered = [
  {
    id: 'id',
    displayName: 'Id',
  },
  {
    id: 'nom_etablissement',
    displayName: 'Nom Etablissement',
  },
  {
    id: 'source',
    displayName: 'Source',
  },
  {
    id: 'titre',
    displayName: 'Titre',
  },
  {
    id: 'valeur',
    displayName: 'Valeur',
  },
]

const columnsSrc = [
  {
    id: 'id',
    displayName: 'Id',
  },
  {
    id: 'Source',
    displayName: 'Source',
  },
  {
    id: 'Axe',
    displayName: 'Axe',
  },
  {
    id: 'titre',
    displayName: 'Titre',
  },
  {
    id: 'valeur',
    displayName: 'Valeur',
  },
]

const modeDisplayStyle = {
  color: '#013755',
  fontWeight: 'bold',
}

const modeUnDisplayStyle = {
  color: 'gray',
  fontWeight: 'normal',
}

const switchContainerStyle = {
  border: '2px gray solid',
  borderRadius: '25px',
  padding: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: '0',
}



function exportFile(data, finess) {
  let parsedData = ''

  for (var i = 0; i < data.length; i++) {
    parsedData = parsedData + data[i].importColumn + '\n'
  }
  let blob = new Blob([parsedData], { type: 'text/plain;charset=utf-8' })
  saveAs(blob, 'importFileATIH-' + finess + '.txt')
}

function AlertDismissible() {
  const [show, setShow] = useState(true)

  if (show) {
    return (
      <Alert variant="info" onClose={() => setShow(false)} dismissible>
        <p>
          <img src={infoimgw} alt="infoimg" width="30px" />
          {'                             '}L'info bulle vous guide sur la
          manière de renseigner votre indicateur
        </p>
        <hr />
        <p className="mb-0">
          <Button color="info">
            <i className="far fa-pencil-square-o"></i>
          </Button>
          {'           '}En cliquant dessus, vous pourrez renseigner votre
          indicateur
        </p>
        <hr />
        <p className="mb-0">
          <Button className="" color="danger">
            Clôturer définitivement la saisie
          </Button>
          {'           '}
          Une fois la saisie des indicateurs terminée, cliquez sur ce bouton. Un
          pop-up vous demandera alors de confirmer votre choix.
        </p>
      </Alert>
    )
  }
  return <Button onClick={() => setShow(true)}>Mode d'emploi</Button>
}

function AlertIfNotCompleted(props) {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  console.log('props', props)
  return (
    <>
      <Button className="" color="danger" onClick={handleShow}>
        Clôturer définitivement la saisie
      </Button>
      <Modal show={show} onHide={handleClose}>
        {props.count !== 0 ? (
          <Modal.Header closeButton>
            <Modal.Title>
              Il vous reste <b>{props.missingIndicators}</b> indicateurs à
              compléter.
            </Modal.Title>
          </Modal.Header>
        ) : (
          <Modal.Header closeButton>
            <Modal.Title>
              La saisie à été complétée dans sa totalité
            </Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body>Souhaitez vous vraiment clôturer la saisie ?</Modal.Body>
        <Modal.Footer>
          <Button color="secondary" onClick={handleClose}>
            Annuler clôture de saisie
          </Button>
          <Button
            color="info"
            onClick={() => {
              props.closeInvestigation(props)
              props.isClosed()
              handleClose()
            }}
          >
            Confirmer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

function matchingArray(array1, array2) {
  for (let i = 0; i < array1.length; i++) {
    for (let j = 0; j < array2.length; j++) {
      array2[j] = array2[j].replace(/\s/g, '')
      if (array1[i] === array2[j]) {
        return true
      }
    }
  }
  return false
}
export default class ListEtb extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedItem: {},
      selectedIndicator: {},
      optionsListitem: [],
      optionsListIndicators: [],
      dataTable: [],
      filteredDataTable: [],
      dataTableDownload: [],
      part: '',
      potentiallyHidden: [],
      conditionnalTriggers: [],
      dataMapping: [],
      key:'',
      isClosed: '',
      modeDisplayAll: false,
      loading: true,
      importATIH: [],
    }
    // console.log('props', this.props.history.location.state)
    this.refreshDataTable = this.refreshDataTable.bind(this)
    this.csvLinkEl = React.createRef()
  }

  componentDidMount() {
    //console.log('history state', this.props.history.location.state)
    if (!this.props.history.location.state) {
      //console.log('redirect')
      this.props.history.push('/')
      return
    }

    this.setState({
      part: this.props.history.location.state.part,
      selectedItem: this.props.history.location.state.data,
    })

    let listEtb = this.props.facilities.map((item) => {
      return { value: item.idEtb, label: item.nomEtb }
    })
    this.setState({ optionsListItem: listEtb })
    this.getDataTableSource(this.props.history.location.state.data.value)

    DataAccessService.get('/getAllIndicators').then((data) => {
      let listIndicators = data.data.map((item) => {
        return { value: item.ID, label: item.Titre }
      })
      this.setState({ optionsListIndicators: listIndicators })
    })

    let conditionnalFields = []
    let conditionnalTriggersTemp = []
    DataAccessService.get('/getDictionary').then((data) => {
      this.setState({ potentiallyHidden: [], conditionnalTriggers: [] })
      let selectorMapping = data.data.map((item) => {
        if (item.dataSelect) {
          item.dataSelect = item.dataSelect.split('; ')
        }
        if (item.Type) {
          item.Type = item.Type.replace(' ', '')
        }
        if (item.isMultiple === 'qcm') {
          item.isMultiple = true
        } else if (item.isMultiple === 'choix unique') {
          item.isMultiple = false
        }
        if (item.Sous_question) {
          ; (item.Sous_question = item.Sous_question.slice(4)),
            (item.Sous_question = item.Sous_question.split(' égale ')),
            (item.Sous_question[1] = item.Sous_question[1].split(' ou '))
        }
        if (item.Sous_question) {
          conditionnalTriggersTemp.push(item.Sous_question[0])
          conditionnalFields.push(item.Id)
        }
        return {
          Id: item.Id,
          Titre: item.Titre,
          Type: item.Type,
          dataSelect: item.dataSelect,
          isMultiple: item.isMultiple,
          sousQuestion: item.Sous_question,
        }
      })
      this.setState({
        dataMapping: selectorMapping,
        potentiallyHidden: conditionnalFields,
        conditionnalTriggers: conditionnalTriggersTemp,
        key: Math.random()
      })
    })
  }

  componentDidUpdate(prevProps) { }

  closeInvestigation(props) {
    console.log('this', this)
    const completion =
      ((props.totalIndicators - props.missingIndicators) /
        props.totalIndicators) *
      100
    DataAccessService.post('/closeInvestigation', {
      facility_id: props.selectedEtb.value,
      facility_name: props.selectedEtb.label,
      email: props.user.email,
      name: props.user.displayName,
      missingValues: props.missingIndicators,
      isFullyCompleted: props.missingIndicators > 0 ? 0 : 1,
      completion: ~~completion,
    })
  }
  async getDataTableSource(id_etb) {
    this.setState({
      dataTable: [],
      dataTableDownload: [],
      filteredDataTable: [],
      loading: true,
    })

    await DataAccessService.get('/getDataListEtbById', {
      year: this.props.yearOfInterest-1,
      id_etb: id_etb,
    }).then((data) => {
      console.log('data Table request', data.data)
      let temp = EtbIdMapper(data.data, this.props.facilities, 'id_etab')
      console.log(temp)
      let dataSource = temp.map((item) => {
        if (item.distinction === null) item.distinction = ''
        else if (item.distinction.search(' ; '))
          item.distinction = item.distinction.split(' ; ')
        if (item.verification_de === null) item.verification_de = ''
        if (item.Sous_question !== null) {
          ; (item.Sous_question = item.Sous_question.slice(4)),
            (item.Sous_question = item.Sous_question.split(' égale ')),
            (item.Sous_question[1] = item.Sous_question[1].split(' ou '))
        }
        return {
          id: item.anap,
          id_etablissement: item.id_etab,
          nom_etablissement: item.Nom_Etablissement,
          axe: item.Axe,
          section: item.section,
          titre: item.Titre,
          source: item.Source,
          ['Verif DE']: item.verification_de,
          distinction: item.distinction,
          info_bulle: item.info_bulle,
          sousQuestion: item.Sous_question,
          valeur: item.valeur,
        }
      })
      // console.log('data Table', dataSource)

      this.setState({ loading: false })

      let dataDownload = data.data.map((item) => {
        return {
          id: item.anap,
          nom_etablissement: item.Nom_Etablissement,
          titre: item.Titre,
          section: item.section,
          source: item.Source,
          valeur: item.valeur,
        }
      })
      // console.log(
      //   'this.filterDataTable(dataSource)',
      //   this.filterDataTable(dataSource),
      // )
      this.setState({
        dataTable: dataSource,
        filteredDataTable: this.filterDataTable(dataSource),
        dataTableDownload: dataDownload,
      })
    })

    DataAccessService.get('/isClosed', { facility_id: id_etb }).then((data) => {
      this.setState({
        isClosed: data.data.length > 0 ? true : false,
      })
    })
  }
  handleSelectPartItem = (select) => {
    this.setState((state, props) => {
      return { selectedItem: select }
    }, console.log('select', select))
  }

  triggerModal = () => {
    this.setState({ isModalShow: true })
  }

  getCommentData = () => {
    return FakedataService.getFakeDataTable()
      .slice(1, 4)
      .map((element) => {
        return {
          comment: element.libelle,
          nom: element.prenom + ' ' + element.nom,
        }
      })
  }

  countMissingValues(array) {
    var count = 0

    for (var i = 0; i < array.length; i++) {
      if (
        !array[i].valeur ||
        array[i].valeur === '' ||
        array[i].valeur === ' ' ||
        array[i].valeur === '.' ||
        array[i].valeur === '?'
      )
        count++
    }
    return count
  }

  handleSwitchComp = (e) => {
    this.setState({ modeDisplayAll: e.target.checked })
  }

  pushSelectedColor = (isAll) => {
    if (isAll)
      return this.state.modeDisplayAll ? modeDisplayStyle : modeUnDisplayStyle
    return !this.state.modeDisplayAll ? modeDisplayStyle : modeUnDisplayStyle
  }

  refreshDataTable = (updateItem, valeur) => {
    const newdata = []
    this.state.dataTable.map((item) => {
      if (item.id === updateItem.id) {
        item.valeur = valeur
        newdata.push(item)
      } else {
        newdata.push(item)
      }
    })
    this.setState({
      dataTable: newdata,
      filteredDataTable: this.filterDataTable(newdata),
    })
  }

  filterDataTable = (dataTable) => {
    if (this.state.modeDisplayAll) {
      return dataTable
    }
    //this.state.dataTable.id
    //parse verif_de en string et l'envoie dans matching array qui compare 2 tableaux et renvoies true s'ils ont un element en commun

    const filter = dataTable.filter(
      (elt) =>
        // ligne de test
        // (elt.id==='105' || elt.id==='106' )&&

        elt['Verif DE'] === 'oui' ||
        (elt.distinction &&
          matchingArray(
            elt.distinction,
            elt['Verif DE'].replace('oui DE ', '').split(' ; oui DE '),
          )),
    )
    console.log(filter)
    const conditionallyHidden = []
    filter.map((item) => {
      if (this.state.potentiallyHidden.includes(item.id)) {
        let infoPotentiallyHiddenElt = this.state.dataMapping.filter(
          (elt) => elt.Id === item.id,
        )
        let valueTriggerElt = dataTable.filter(
          (elt) => elt.id === infoPotentiallyHiddenElt[0].sousQuestion[0],
        )
        infoPotentiallyHiddenElt[0].sousQuestion[1] = infoPotentiallyHiddenElt[0].sousQuestion[1].map(
          (v) => v,
        )
        //a reactiver pour les questions conditionnelles sur la base en prod
        // if (
        //   !infoPotentiallyHiddenElt[0].sousQuestion[1].includes(
        //     valueTriggerElt[0].valeur.toLowerCase().slice(0, -1),
        //   )
        // ) {
        //   conditionallyHidden.push(item.id)
        // }
      }
    })
    const dataShow = []
    filter.map((item) => {
      if (!conditionallyHidden.includes(item.id)) {
        dataShow.push({
          id: item.id,
          id_etablissement: item.id_etablissement,
          nom_etablissement: item.nom_etablissement,
          axe: item.axe,
          section: item.section,
          titre: item.titre,
          source: item.source,
          info_bulle: item.info_bulle,
          valeur: item.valeur,
        })
      }
    })
    console.log('item = ', dataShow)

    return dataShow
  }

  isAllEtbSelected = () => {
    if (this.state.selectedItem) return this.state.selectedItem.value === -1
    return false
  }
  fetchImportATIH = async () => {
    this.props.setLoad(true)
    const finess = await DataAccessService.get(
      '/finess/' + this.state.selectedItem.value,
    )
    const data = await DataAccessService.get('/export/' + finess.data[0].Finess)
    console.log('data import ATIH', data.data)
    // this.setState({ importATIH: data.data }, () => {
    // setTimeout(() => {
    //   this.csvLinkEl.current.link.click();
    // });
    // });
    exportFile(data.data, finess.data[0].Finess)
    this.props.setLoad(false)
  }
  render() {

    return (
      <div className="mx-auto row col-10">
        <div className="px-2 py-3">
          <div className="py-3 row justify-content-center">
            {/* <div className="col-4">
              <Select
                placeholder="Rechercher un établissement"
                options={this.state.optionsListItem}
                onChange={(select) => this.handleSelectPartItem(select)}
              />
            </div>

            <div className="col-2">
              <Button
                color="info"
                className="h-35"
                onClick={() =>
                  this.getDataTableSource(this.state.selectedItem.value)
                }
              >
                Valider
              </Button>
            </div> */}

          </div>
          {!this.state.isClosed && <AlertDismissible />}
          {this.state.isClosed === true && (
            <Alert variant="danger">
              L'enquête pour cet établissement a été clôturée.
            </Alert>
          )}

          <div className="py-2"></div>
          <div className="mx-0 row">
          <h3>{this.state.selectedItem.label ? this.state.selectedItem.label : ''}</h3>
            <a
              href="/"
              style={{ 'text-decoration': 'none' }}
              className="clickable px-2 text-secondary d-flex align-items-center"
            >
              <div>(changer</div>
              <img className="px-2" height={'10px'} src={changeicon} />
              <div>)</div>
            </a>
            <div className="ml-auto row justify-content-center mr-0 pr-0">
              {this.state.isClosed === false &&
                this.state.filteredDataTable !== [] && (
                  <AlertIfNotCompleted
                    missingIndicators={this.countMissingValues(
                      this.state.filteredDataTable,
                    )}
                    closeInvestigation={this.closeInvestigation}
                    selectedEtb={this.state.selectedItem}
                    user={this.props.user}
                    totalIndicators={this.state.filteredDataTable.length}
                    isClosed={() => this.setState({ isClosed: true })}
                  />
                )}
              {this.props.isAdmin && (
                <div className="px-2 d-flex justify-content-center align-items-center mr-2">
                  <Button
                    color="info"
                    className="h-35"
                    type="button"
                    onClick={this.fetchImportATIH}
                  >
                    <i className="px-2 far fa-arrow-alt-circle-down"></i>
                    Fichier import site ATIH
                  </Button>
                  <Button
                    style={{ display: 'none' }}
                    ref={this.csvLinkEl}
                  ></Button>
                  {/* <CSVLink
                      className="download mx-2"
                      data={this.state.importATIH}
                      filename={"importFileATIH.csv"}
                      ref={this.csvLinkEl}
                    > */}
                  {/* <Button
                        color="info"
                        className="h-35"
                        disabled={this.state.dataTable.length > 0 ? false : true}
                        onClick={this.fetchImportATIH}
                      >
                        <i className="px-2 far fa-arrow-alt-circle-down"></i>
                        Import site ATIH
                      </Button> */}
                  {/* </CSVLink> */}
                  <CsvDownloader
                    className="download"
                    filename={
                      'Tableau des indicateurs - ' +
                      this.state.selectedItem.label
                    }
                    separator=";"
                    wrapColumnChar=""
                    columns={
                      this.state.part === 'etb' ? columnsEtb : columnsSrc
                    }
                    datas={this.state.dataTableDownload}
                  >
                    <Button
                      color="info"
                      className="h-35"
                      disabled={this.state.dataTable.length > 0 ? false : true}
                    >
                      <i className="px-2 far fa-arrow-alt-circle-down"></i>
                      Télécharger données
                    </Button>
                  </CsvDownloader>
                </div>
              )}

              {!this.props.isAdmin && (
                <div className="px-2 d-flex justify-content-center align-items-center mr-4">
                  <div className="mr-2">
                    <CsvDownloader
                      className="download"
                      filename={
                        'Tableau des indicateurs à renseigner - ' +
                        this.state.selectedItem.label
                      }
                      separator=";"
                      wrapColumnChar=""
                      columns={
                        this.state.part === 'etb' ? columnsEtb : columnsSrc
                      }
                      datas={this.state.filteredDataTable}
                    >
                      <Button
                        color="info"
                        className="h-35"
                        disabled={
                          this.state.dataTable.length > 0 ? false : true
                        }
                      >
                        <i className="px-2 far fa-arrow-alt-circle-down"></i>
                        Télécharger
                      </Button>
                    </CsvDownloader>
                  </div>
                </div>
              )}
              {/*<div>
                    <CsvDownloader
                      className="download"
                      filename={
                        'Tableau des indicateurs - ' +
                        this.state.selectedItem.label
                      }
                      separator=";"
                      wrapColumnChar=""
                      columns={
                        this.state.part === 'etb' ? columnsEtb : columnsSrc
                      }
                      datas={this.state.dataTableDownload}
                    >
                      <Button
                        color="info"
                        className="h-35"
                        disabled={
                          this.state.dataTable.length > 0 ? false : true
                        }
                      >
                        <i className="px-2 far fa-arrow-alt-circle-down"></i>
                        Télécharger données complètes
                      </Button>
                    </CsvDownloader>
                  </div>
                </div>
              {/* )} */}

              {/* <div className="px-2 d-flex justify-content-start align-items-center">
                <ModalComment
                  title="Commentaire général"
                  data={this.getCommentData()}
                  lang={this.props.lang}
                />
              </div> */}

              {this.props.isAdmin && (
                <div
                  class="custom-control custom-switch"
                  style={switchContainerStyle}
                >
                  <p className="h4 mb-0 mr-2">Vue DE</p>

                  <label
                    for="customSwitch1"
                    style={{
                      marginRight: '45px',
                      marginBottom: '0px',
                      ...{ ...this.pushSelectedColor(false) },
                    }}
                  >
                    On
                  </label>

                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="customSwitch1"
                    onChange={this.handleSwitchComp}
                  />
                  <label
                    class="custom-control-label"
                    for="customSwitch1"
                    style={{ ...this.pushSelectedColor(true) }}
                  >
                    Off
                  </label>
                </div>
              )}
            </div>
          </div>

          <div className="pt-3 d-flex position-relative">
            {this.props.load && <Loader />}
            {this.state.loading && <Loader position="relative" />}
            <TableList
              dataTable={
                this.state.modeDisplayAll
                  ? this.state.dataTable
                  : this.state.filteredDataTable
              }
              filter_on={this.state.modeDisplayAll}
              dataMapping={this.state.dataMapping}
              refreshDataTable={this.refreshDataTable}
              // conditionnalTriggers={this.state.conditionnalTriggers}
              setLoad={this.props.setLoad}
              isClosed={this.state.isClosed}
              yearOfInterest={this.props.yearOfInterest}
              key={this.state.key}
            />
          </div>
          <div className="row justify-content-end mr-1">
            <div>
              {this.state.isClosed === false &&
                this.state.filteredDataTable !== [] && (
                  <AlertIfNotCompleted
                    missingIndicators={this.countMissingValues(
                      this.state.filteredDataTable,
                    )}
                    closeInvestigation={this.closeInvestigation}
                    selectedEtb={this.state.selectedItem}
                    user={this.props.user}
                    totalIndicators={this.state.filteredDataTable.length}
                    isClosed={() => this.setState({ isClosed: true })}
                  />
                )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

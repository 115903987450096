import React, { useState } from 'react'
import '../../App.scss'
import { Redirect } from 'react-router-dom'
import ClipLoader from 'react-spinners/ClipLoader'
import { t } from '../../store/translations'
import Alert from 'react-bootstrap/Alert'

export default function Login(props) {
  const [loading, setLoading] = useState(false)
  console.log(props)
  const texteAcceuil = ` Bienvenue sur la plate-forme de préparation à la campagne 2024 des tableaux de bord ESMS de l’ANAP.
   Cette plate-forme vous permettra de visualiser, vérifier et saisir l’ensemble des indicateurs pour les établissements et services Korian pour lesquels vous êtes référencé.
    `
  return (
    <div className="">
      {props.loggedIn && <Redirect to="/" />}
      <div className="py-3 py-lg-4"></div>
      <div className="d-flex col-10 align-items-center m-auto px-3 h3">
        {texteAcceuil}
      </div>
      <div className="py-3 py-lg-4"></div>
      <div className="d-flex align-items-center m-auto px-3">
        <div
          className="txt rounded bg-one m-auto px-3 py-2 clickable"
          onClick={props.login}
        >
          {!loading ? (
            <div className="text-white text-center">
              {t.SSO_button[props.lang]}
            </div>
          ) : (
            <ClipLoader
              sizeUnit={'px'}
              size={25}
              color={'#ffffff'}
              loading={loading}
            />
          )}
        </div>
      </div>
      {!props.isAuthorized && !props.facilityEmpty && (
        <div className="m-5">
          <Alert variant="info" className="text-center col-10 offset-1">
            Bonjour {props.user.email}, vous n'avez pas accès à l'application.
            Contactez{' '}
            <Alert.Link
              className="text-dark"
              href="mailto:contact-kdto@korian.com"
            >
               contact-kdto@korian.com
            </Alert.Link>
            {' '}pour demander accès.
          </Alert>
        </div>
      )}
      {!props.isAuthorized && props.facilityEmpty && (
              <div className="m-5">
              <Alert variant="info" className="text-center col-10 offset-1">
                Bonjour {props.user.email}, vous n'avez accès à aucun établissement.
                Contactez{' '}
                <Alert.Link
                  className="text-dark"
                  href="mailto:contact-kdto@korian.com"
                >
                   contact-kdto@korian.com
                </Alert.Link>
                {' '}pour demander accès, en spécifiant le nom de votre établissement.
              </Alert>
            </div>
      )}

      <div className="py-3 py-lg-5"></div>
      <div className="py-5"></div>
    </div>
  )
}

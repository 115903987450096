import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import NavBar from './components/Layout/Navbar'
import Footer from './components/Layout/Footer'
import { config } from './config'
import Main from './pages/Main'
import withAuthProvider from './helpers/AuthProvider'
import './App.scss'

const axios = require('axios')

function App(props) {
  const [country_code, setCountry_code] = useState('FR')
  const [lang, setLang] = useState('FR')
  const [isAdmin, setIsAdmin] = useState(false)
  const [facilities, setFacilities] = useState({})
  const [isAuthorized, setIsAuthorized] = useState(true)
  const [loggedIn, setLoggedIn] = useState(false)
  const [load, setLoad] = useState(false)
  const [yearOfInterest, setYearOfInterest] = useState(0)
  const [apiRefToken, setApiRefToken] = useState('')
  const [facilityEmpty, setFacilityEmpty] = useState(false)

  const authorizedUsers = [
    'akram.benabderrahmane-ext@korian.fr',
    'luc.gille@korian.com',
    'francois.dechalendar@korian.fr',
    'thomas.berthier@korian.fr',
  ]

  useEffect(() => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization':props.accessToken
    }
    setLoad(true)
    if (props.user.email) {
      axios
        .post(config.apimref + '/auth/B2C', {
          token: props.accessToken,
          email: props.user.email,
        },
        { headers: headers }
        )
        .then((ans) => {
          console.log('ans', ans)
          if (
            ans.data.access_token === '' ||
            (!ans.data.job_access.includes(22) &&
              !ans.data.user_access.includes(22) &&
              !ans.data.job_access.includes(23) &&
              !ans.data.user_access.includes(23))
          ) {
            setIsAuthorized(false)
          } else {
            setApiRefToken(ans.data.access_token)
            let adminAnap = false
            if (ans.data.job_access.includes(22)) {
              adminAnap = true
            }
            if (ans.data.user_access.includes(22)) {
              adminAnap = true
            }
            const header = {
              headers: { Authorization: 'Bearer ' + ans.data.access_token },
            }
            setIsAdmin(adminAnap)
            axios
              .get(
                config.backendSso + '/api/facilities?customFilter=concerneANAP',
                header,
              )
              .then((resp) => {
                console.log(resp.data)
                if (!adminAnap) {
                  axios
                    .get(
                      config.backendSso + '/api/users/' + ans.data.userId + '?',
                      header,
                    )
                    .then((userFac) => {
                      let items = []
                      userFac.data.facilities.forEach((element) =>
                        items.push(element.id),
                      )
                      let restrictedFacs = resp.data.filter((el) =>
                        items.includes(el.id),
                      )
                      if (restrictedFacs.length > 0) {
                        setIsAuthorized(true)
                        setFacilities(restrictedFacs)
                        setLoggedIn(true)
                      } else {
                        setIsAuthorized(false)
                        setLoggedIn(false)
                        setFacilityEmpty(true)
                      }
                    })
                    .catch((e) => {
                      console.log(e)
                    })
                } else {
                  setIsAuthorized(true)
                  setFacilities(resp.data)
                  setLoggedIn(true)
                }
              })
              .catch((e) => {
                console.log(e)
              })
          }
          setLoad(false)
        })
        .catch((err) => {
          setIsAuthorized(false)
          setLoad(false)
        })
      //   setLoggedIn(config.loggedIn ?? props.isAuthenticated) // If the config loggedIn field is not set, the default config will be use
    }
    props.error ?? console.log('error from SSO: ', props.error)
  }, [props.user])

  useEffect(async () => {
    let temp = await axios.get(config.backendUrl + '/getYear')
    setYearOfInterest(temp.data[0].year)
  }, [])

  return (
    <div className="App">
      <Router>
        <NavBar
          isAuthenticated={loggedIn}
          lang={lang}
          //   userType={userType}
          setLang={setLang}
          user={props.user}
          isAdmin={isAdmin}
          authButtonMethod={loggedIn ? props.logout : props.login}
          logoutButton={loggedIn ? props.login : props.logout}
          isAuthorized={isAuthorized}
          authorizedUsers={authorizedUsers}
        />
        <Main
          loggedIn={loggedIn}
          lang={lang}
          isAdmin={isAdmin}
          login={props.login}
          isAuthorized={isAuthorized}
          facilities={facilities}
          user={props.user}
          setLoad={setLoad}
          load={load}
          yearOfInterest={yearOfInterest}
          setYearOfInterest={setYearOfInterest}
          authorizedUsers={authorizedUsers}
          facilityEmpty={facilityEmpty}
          instance={props.msalInstance}
        />
        <Footer
          country_code={country_code}
          setCountry_code={setCountry_code}
          lang={lang}
        />
      </Router>
    </div>
  )
}

export default withAuthProvider(App)

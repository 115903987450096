import React from 'react'
import Logo from '../../assets/img/logowhite.png'

import {
  Button,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import { NavLink as RouterNavLink } from 'react-router-dom'
import '@fortawesome/fontawesome-free/css/all.css'

const activeLinkColor = { fontWeight: 'bold', color: 'red' }

function UserAvatar(props) {
  // If a user avatar is available, return an img tag with the pic
  if (props.user.avatar) {
    return (
      <img
        src={props.user.avatar}
        alt="user"
        className="rounded-circle align-self-center"
        style={{ width: '32px' }}
      ></img>
    )
  }
  // No avatar available, return a default icon
  return (
    <i
      className="far fa-user-circle fa-lg rounded-circle align-self-center"
      style={{ width: '32px' }}
    ></i>
  )
}

function AuthNavItem(props) {
  // If authenticated, return a dropdown with the user's info and a
  // sign out button
  if (props.isAuthenticated) {
    return (
      <div className="d-flex align-items-center">
        <UncontrolledDropdown>
          <DropdownToggle nav className="px-2">
            <UserAvatar user={props.user} />
          </DropdownToggle>
          <DropdownMenu right>
            <h5 className="dropdown-item-text mb-0">
              {props.user.displayName}
            </h5>
            <p className="dropdown-item-text text-muted mb-0">
              {props.user.email}
            </p>
            {props.userType === 'admin' && (
              <p className="text-danger text-center">
                <strong>(Compte admin)</strong>
              </p>
            )}
            <DropdownItem divider />
            <DropdownItem onClick={props.authButtonMethod}>
              Déconnexion
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
        {/* <SetLanguage setLang={props.setLang} lang={props.lang} /> */}
      </div>
    )
  }

  // Not authenticated, return a sign in link
  return (
    <NavItem className="d-flex">
      {!props.isAuthorized && (
        <Button
          onClick={props.logoutButton}
          className="txt text-white nav-link border-0"
          color=""
        >
          Déconnexion
        </Button>
      )}
      {/* <SetLanguage setLang={props.setLang} lang={props.lang} /> */}
    </NavItem>
  )
}
function MenuAdmin(props) {
  //desactive les onglets de la navbar
  const menuSuperAdminItems = [
    { id: 1, libelle: "Selection d'année", route: '/year-selector' },
    // { id: 2, libelle: 'Atypies', route: '/atypies' },
    { id: 3, libelle: 'Enquêtes clôturées DE', route: '/closed-investigation' },
    { id: 4, libelle: 'Télécharger toute la base', route: '/download' },
    // { id: 4, libelle: 'Importer des données', route: '/import' },
    { id: 5, libelle: '% completion', route: '/completion' },
  ]
  const menuAdminItems = [
    // { id: 1, libelle: 'Atypies', route: '/atypies' },
    { id: 2, libelle: 'Enquêtes clôturées DE', route: '/closed-investigation' },
    { id: 3, libelle: 'Télécharger toute la base', route: '/download' },
    // { id: 4, libelle: 'Importer des données', route: '/import' },
    { id: 4, libelle: '% completion', route: '/completion' },
  ]
  console.log(props)

  return (
    <>
      {props.isAuthenticated &&
      props.authorizedUsers.includes(props.user.email) ?
      menuSuperAdminItems.map((elt, index) => {
          return (
            <NavItem key={elt.id}>
              <RouterNavLink
                to={{ pathname: elt.route, state: elt.state }}
                activeStyle={activeLinkColor}
                className={`nav-link hideonmobile`}
                exact
              >
                {elt.libelle}
              </RouterNavLink>
            </NavItem>
          )
        }):
        menuAdminItems.map((elt, index) => {
          return (
            <NavItem key={elt.id}>
              <RouterNavLink
                to={{ pathname: elt.route, state: elt.state }}
                activeStyle={activeLinkColor}
                className={`nav-link hideonmobile`}
                exact
              >
                {elt.libelle}
              </RouterNavLink>
            </NavItem>
          )
        })}
    </>
  )
}

export default class NavBar extends React.Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: false,
    }
    this.isIE = false || !!document.documentMode
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }
  render() {
    return (
      <Navbar className="bg-korianblue white justify-content-start">
        <NavbarBrand href="/">
          <img src={Logo} className="mr-4" alt="logo korian" />
        </NavbarBrand>
        Outil de centralisation ANAP
        {this.isIE ? (
          <div className="collapse show ml-auto">
            <Nav className="">
              <AuthNavItem
                isAuthenticated={this.props.isAuthenticated}
                authButtonMethod={this.props.authButtonMethod}
                lang={this.props.lang}
                setLang={this.props.setLang}
                user={this.props.user}
                userType={this.props.userType}
                isIE={this.isIE}
                logout={this.props.logout}
                logoutButton={this.props.logoutButton}
                isAuthorized={this.props.isAuthorized}
              />
            </Nav>
          </div>
        ) : (
          <div className="collapse show ml-auto">
            <Nav className="">
              {this.props.isAdmin ? (
                <MenuAdmin
                  lang={this.props.lang}
                  isAuthenticated={this.props.isAuthenticated}
                  user={this.props.user}
                  authorizedUsers={this.props.authorizedUsers}
                />
              ) : (
                ''
              )}

              <AuthNavItem
                isAuthenticated={this.props.isAuthenticated}
                authButtonMethod={this.props.authButtonMethod}
                lang={this.props.lang}
                setLang={this.props.setLang}
                userType={this.props.userType}
                user={this.props.user}
                logoutButton={this.props.logoutButton}
                isAuthorized={this.props.isAuthorized}
              />
            </Nav>
          </div>
        )}
      </Navbar>
    )
  }
}

import React, { Component, useState, useEffect, useMemo } from 'react'
import BTable from 'react-bootstrap/Table'
import { Button, Row, Col, Grid } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import './TableList.scss'
import DataAccessService from '../../services/data-acces.service'
import ReactTooltip from 'react-tooltip'
import infoimgw from '../../assets/img/icon_blue.png'
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from 'react-table'
import Form from 'react-bootstrap/Form'

const inputEditStyle = { width: '80%', marginBottom: '5px' }
const spanValueStyle = { width: '70%' }
const emptyData = [{ id: 0, data: 'Tableau vide' }]

const yearOfInterest = 2024

const capitalize = (texte) => {
  if (texte) {
    if (typeof texte !== 'string') return texte
    return texte.charAt(0).toUpperCase() + texte.slice(1)
  }
}

function TitleField(props) {
  return (
    <div className="d-flex justify-content-between align-items-center flex-wrap">
      <span
        className="ml-2"
        data-for="commentaire"
        data-tip={props.dataRow.info_bulle}
      >
        {props.dataRow.titre + '      '}
        {props.dataRow.info_bulle && (
          <img src={infoimgw} alt="infoimg" width="20px" />
        )}
      </span>
      <ReactTooltip
        id="commentaire"
        place="top"
        effect="solid"
        multiline={true}
      />
    </div>
  )
}

function InputField(props) {
  let inputReference = null;
  const [edited, setEdited] = useState(false)
  const [valeurInput, setValeurInput] = useState(props.valeur)

  useEffect(() => {
    var mapping=dataMappingFunc(props.dataRow.id);
    if (
      mapping && mapping.Type === 'select' &&
      typeof valeurInput === 'string'
    ) {
      let decompValue = valeurInput.split(';')
      if (decompValue.length > 1) {
        decompValue[decompValue.length] = ''
        decompValue = decompValue.slice(0, -1)
      }
      setValeurInput(decompValue.map((x) => x))
    }
  }, [])

  useEffect(() => {
    if (inputReference) {
      inputReference.focus()
    }
  })

  /**
   * @param {*} e
   * @param {*} type 1- Text or Num | 2- Textarea | 3- Select | 4- Date
   */
  const handleChange = (e, type) => {
    if (type === 3) {
      setValeurInput(e)
    } else if (type === 4) {
      setValeurInput(moment(e).format('DD/MM/YYYY'))
    } else {
      setValeurInput(e.target.value)
    }
  }

  const getRandomKey = () => {
    return Math.floor(Math.random() * 1000)
  }

  const handleButton = () => {
    setValeurInput(props.valeur)
    setEdited(!edited)
  }

  const recordData = () => {
    let valeur =
      typeof valeurInput === 'object'
        ? Array.isArray(valeurInput)
          ? valeurInput.join(';').replace(/'/g, "''")
          : valeurInput.value
        : valeurInput
    valeur.endsWith(' ') ? '' : (valeur = valeur + ' ')
    // if (props.conditionnalTriggers.includes(props.dataRow.id)) {
    props.refreshDataTable(props.dataRow, valeur)
    // }
    const dataToSend = {
      id_anap: props.dataRow.id,
      id_etab: props.dataRow.id_etablissement,
      annee_reporting: yearOfInterest-1,
      valeur: valeur.replace(/'/g, "''"),
    }
    props.setLoad(true)
    DataAccessService.post('/editValueIndicForEtab', dataToSend).then(
      (data) => {
        setEdited(!edited)
        props.setLoad(false)
      },
    )
  }

  const dataMappingFunc = (mapId) => {
    return props.dataMapping.find((x) => x.Id.toString() === mapId.toString())
  }

  function moveCaretAtEnd(e) {
    var temp_value = e.target.value
    e.target.value = ''
    e.target.value = temp_value
  }


  function TextField(isText) {
    if (isText === 'text')
      return (
        <textarea
          autoFocus="autoFocus"
          ref={(input) => {
            inputReference = input
          }}
          key={getRandomKey()}
          value={valeurInput}
          onFocus={moveCaretAtEnd}
          onChange={(e) => handleChange(e, 1)}
          cols="25" rows="2"
        />
      )
    else
      return (
        <>
          <input
            ref={(input) => {
              inputReference = input
            }}
            step={isText === 'nombre' ? '1' : '0,01'}
            // key={Math.floor(Math.random() * 1000)}
            type="number"
            value={Number(valeurInput)}
            onChange={(e) => handleChange(e, 1)}
            style={inputEditStyle}
          />
          <span
            className="ml-2"
            data-for="float-comment"
            data-tip="Ce champ accepte les valeurs décimales au format : 1,06"
          >
            <img src={infoimgw} alt="infoimg" width="20px" />
          </span>
          <ReactTooltip
            id="float-comment"
            place="top"
            effect="solid"
            multiline={true}
          />
        </>
      )
  }

  const SelectField = (data) => {
    const selectOptionsElement = data.dataSelect.map((x) => {
      return { value: x, label: x }
    })

    const setValueSelect = () => {
      if(selectOptionsElement)
      return selectOptionsElement.filter((option) =>
        valeurInput.includes(option.value),
      )
    }

    return (
      <Select
        key={getRandomKey()}
        className="w-100"
        options={selectOptionsElement}
        isMulti={data.isMultiple}
        isClearable
        value={setValueSelect()}
        menuPortalTarget={document.body}
        onChange={(option) =>
          handleChange(
            data.isMultiple ? option.map((item) => item.value) : option.value,
            3,
          )
        }
      />
    )
  }

  const range = (start, end) => {
    const length = end - start
    return Array.from({ length }, (_, i) => start + i)
  }

  const DateField = () => {
    const years = range(1950, 2030)
    const months = [
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Août',
      'Septembre',
      'Octobre',
      'Novembre',
      'Decembre',
    ]
    return (
      <DatePicker
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 10,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              {'<'}
            </button>
            <select
              value={date.getUTCFullYear()}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <select
              value={months[date.getMonth()]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              {'>'}
            </button>
          </div>
        )}
        key={getRandomKey()}
        dateFormat="dd/MM/yyyy"
        value={valeurInput}
        selected={new Date()}
        onChange={(e) => handleChange(e, 4)}
      />
    )
  }

  const ElementField = (props) => {
    const { dataRowId } = props
    const dataElement = dataMappingFunc(dataRowId)

    switch (dataElement.Type) {
      case 'nombre':
        return TextField('nombre')
      case 'text':
        return TextField('text')
      case 'select':
        return SelectField(dataElement)
      case 'date':
        return DateField()
      case 'float':
        return TextField('float')
      default:
        return TextField('text')
    }
  }

  const ContainerField = () => {
    return (
      <div className="d-flex justify-content-around align-items-center flex-wrap">
        <ElementField dataRowId={props.dataRow.id} />

        <Button variant="secondary" onClick={handleButton}>
          <i className="fa fa-times"></i>
        </Button>

        <Button variant="success" onClick={() => recordData()}>
          <i className="far fa-check-circle"></i>
        </Button>
      </div>
    )
  }

  const displayInputValue = () => {
    if (typeof valeurInput === 'object' && valeurInput !== null) {
      if (Array.isArray(valeurInput)) return valeurInput.join(';\n')
      return valeurInput.value
    }
    return valeurInput
  }

  function findLockedValues(dataRow) {
    // const dataElement = dataMappingFunc(dataRow.id)
    return true
  }

  return (
    <div>
      {!edited ? (
        <div className="d-flex justify-content-between align-items-center flex-wrap">
          <span style={spanValueStyle}>
            {' '}
            {capitalize(displayInputValue())}{' '}
          </span>
          {!props.isClosed && findLockedValues(props.dataRow) && (
            <Button variant="info" onClick={handleButton}>
              <i className="far fa-pencil-square-o"></i>
            </Button>
          )}
        </div>
      ) : (
        <ContainerField />
      )}
    </div>
  )
}

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <div className="input-group">
      <label className="my-auto">Recherche globale: </label>
      <input
        className="form-control col-6 mx-3 border rounded"
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value)
          onChange(e.target.value)
        }}
        placeholder={`Entrez un indicateur, nom de section, sous-section, titre ou valeur`}
        style={{
          fontSize: '1.1rem',
          border: '0',
        }}
      />
    </div>
  )
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length

  return (
    <Form.Control
      type="text"
      placeholder="Rechercher valeur"
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
    />
  )
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] })
}

fuzzyTextFilterFn.autoRemove = (val) => !val

function Table({ columns, data, props, hiddenColumnsFiltered }) {
  useEffect(() => {
    gotoPage(0)
  }, [props.filter_on])

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    [],
  )

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    [],
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: {
        hiddenColumns: props.filter_on
          ? [
              'info_bulle',
              'nom_etablissement',
              'sousQuestion',
              'id_etablissement',
            ]
          : [
              'info_bulle',
              'nom_etablissement',
              'sousQuestion',
              'distinction',
              'id_etablissement',
              'source',
            ],
        pageSize: 10,
      },
      pageIndex: 1,
      autoResetPage: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  )

  // Render the UI for your table
  return (
    <div className="table-responsive">
      <div className="py-2" />
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <div className="py-3" />
      <BTable
        className="table mb-0"
        style={{ width: 100 + '%' }}
        bordered
        hover
        size="sm"
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps({
                    style: { width: column.width + '%', height: '100px' },
                  })}
                  className="align-middle px-3 text-center"
                >
                  <div {...column.getSortByToggleProps()}>
                    {column.render('Header')}
                  </div>
                  <div>
                    {column.canFilter &&
                    column.id !== 'valeur' &&
                    column.id !== 'id'
                      ? column.render('Filter')
                      : null}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <>
                {(i === 0 ||
                  (i !== 0 &&
                    page[i].values.axe !== page[i - 1].values.axe)) && (
                  <tr style={{ height: '30px' }} className="border">
                    <td
                      colSpan={props.filter_on ? '9' : '6'}
                      className="font-weight-bold px-2 align-middle text-center text-white bg-secondary"
                    >
                      {page[i].values.axe}
                    </td>
                  </tr>
                )}
                <tr
                  {...row.getRowProps({
                    style: { height: '90px' },
                  })}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={
                          cell.column.id === 'id'
                            ? 'px-2 align-middle text-center'
                            : 'px-2 align-middle'
                        }
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              </>
            )
          })}
        </tbody>
      </BTable>
      <div className="pagination py-3 float-right">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <span className="mx-2">
          Page{' '}
          <strong>
            {pageIndex + 1} sur {pageOptions.length}
          </strong>{' '}
        </span>
      </div>
    </div>
  )
}

export default function (props) {
  console.log(props.dataTable)
  const headersInfo = [
    {
      id: 'id',
      name: 'Id',
      width: 2,
    },
    {
      id: 'id_etablissement',
      name: 'Id Etablissement',
      width: 5,
    },
    {
      id: 'nom_etablissement',
      name: 'Nom Etablissement',
      width: 10,
    },
    {
      id: 'axe',
      name: 'Axe',
      width: 8,
    },
    {
      id: 'sous_section',
      name: 'Sous Section',
      width: 10,
    },
    {
      id: 'section',
      name: 'Section',
      width: 12,
    },
    {
      id: 'titre',
      name: 'Titre',
      width: 15,
      Cell: ({ cell: { value, row } }) => (
        <TitleField
          dataRow={row.values}
          valeur={value}
          dataMapping={props.dataMapping}
        />
      ),
    },
    {
      id: 'source',
      name: 'Source',
      width: 10,
    },
    {
      id: 'info_bulle',
      name: 'Info Bulle',
      width: 5,
    },
    {
      id: 'distinction',
      name: 'Distinction',
      width: 5,
    },
    {
      id: 'sousQuestion',
      name: 'Sous Question',
      width: 5,
    },
    {
      id: 'valeur',
      name: 'Valeurs ' + (props.yearOfInterest - 1),
      width: 15,
      Cell: ({ cell: { value, row } }) => (
        <InputField
          dataRow={row.values}
          valeur={value}
          dataMapping={props.dataMapping}
          refreshDataTable={props.refreshDataTable}
          setLoad={props.setLoad}
          isClosed={props.isClosed}
          filter_on={props.filter_on}
        />
      ),
    },
    {
      id: 'Verif DE',
      name: 'Verif DE',
      width: 8,
    },
  ]

  const columns = React.useMemo(
    () =>
      props.dataTable[0] ? fillHeaders(Object.keys(props.dataTable[0])) : [],
    [props.dataTable],
  )

  function fillHeaders(array) {
    let headers = []
    array.forEach((el, index) => {
      let temp = {}
      headers[index] = {}
      temp = headersInfo.forEach((element) => {
        if (element.id === el) {
          headers[index]['Header'] = element.name
          headers[index]['width'] = element.width
          if (element.Cell) headers[index]['Cell'] = element.Cell
        }
        headers[index]['accessor'] = el
      })
    })
    return headers
  }

  return (
    <>
      <Table columns={columns} data={props.dataTable} props={props} />
    </>
  )
}

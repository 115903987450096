import { config } from '../config'
import axios from 'axios'

export default class DataAccessService {
  static get(url, params) {
    let apiToCall = ''
    if (params) {
      let paramsString = ''
      Object.keys(params).forEach((element) => {
        paramsString += `${element}=${params[element]}&`
      })
      paramsString = paramsString.slice(0, -1)
      apiToCall = `${config.backendUrl}${url}?${paramsString}`
    } else {
      apiToCall = `${config.backendUrl}${url}`
    }

    return axios.get(apiToCall).then((data) => {
      return {
        headers: data.headers,
        data: data.data,
        status: data.status,
        statusText: data.statusText,
      }
    })
  }

  static post(url, data) {
    const apiToCall = `${config.backendUrl}${url}`
    return axios.post(apiToCall, data).then((data) => {
      return {
        headers: data.headers,
        data: data.data,
        status: data.status,
        statusText: data.statusText,
      }
    })
  }
}

import React from 'react'
import load from '../../assets/img/loader.gif'

export default function Loader(props) {
  return (
    <div
      className={`loader ${
        props.position === 'relative' ? 'position-absolute' : ''
      }`}
    >
      <img alt="loading" src={load} />
    </div>
  )
}
